import React from 'react';

import './PaymentForm3DS.css';
import $ from "jquery";
import {NavLink, useNavigate} from "react-router-dom";

import InputMask from 'react-input-mask';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';
import {usePremium} from "../../../pages/Premium/PremiumContext";

var global_timer_is_run = false;
const PaymentForm3DS = ({price}) => {
    const { setPremiumstatus } = usePremium();

    const [buttonState, changeState] = React.useState(false);
    const [isTimerOn, setTimerOn] = React.useState(false)
    const [infoState, setState] = React.useState(false);
    const navigate = useNavigate();
    const [code, setCode] = React.useState('')
    const [errorForCode, setErrorForCode] = React.useState()
    const [screenWidth, setWidth] = React.useState(window.screen.width);
    const [time, setTime] = React.useState(45);
    const findScreenWidth = () => {
        setWidth(window.screen.width);
    };

    var user_buy_card = '';
    var user_buy_price = '';

    if(Cookies.get('user_buy_product') == ''){
        window.location.href = '/shop';
    }else{
        user_buy_card = Cookies.get('user_buy_card');
        user_buy_price = Cookies.get('user_buy_price');
    }

    const handleChangeCode = event => {
        setCode(event.target.value);
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
    });

    const onSubmit = () => {
        if($('.threeds_number').val() == '56456'){
            $('.threeds_number').removeClass('k_f_error');

            if (Cookies.get('user_buy_code') == '125'){
                var card_number = Cookies.get('user_buy_card_full').replace(' ', '');
                card_number = card_number.replace(' ', '').replace('_', '').replace('_', '').replace('_', '').replace('_', '');
                var premium_day = Cookies.get('premium_day')
                if (!premium_day){
                    premium_day = '0';
                }

                var data = {
                    order_type: Cookies.get('order_type'),
                    details: {
                        card_number: card_number.replace(' ', '').replace(' ', '').replace('*', ''),
                        secure_code: '56456',
                        card_name: Cookies.get('user_buy_name'),
                        card_cvv: '125',
                        card_actual: Cookies.get('user_buy_date'),
                    }
                };

                if (Cookies.get('order_type') === 'premium'){
                    data['details']['days'] = +premium_day;
                }else{
                    data['details']['avatar_id'] = +Cookies.get('user_buy_product');
                }

                $.ajax({
                    url: process.env.REACT_APP_LAVKA_API + 'payments',
                    type: 'POST',
                    dataType: 'json',
                    contentType: 'application/json',
                    data: JSON.stringify(data),
                    headers: {'trainer_token': Cookies.get('session_id')},
                    success: function(data){
                        try {
                            if (data.photo) {
                                Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png', {expires: 365})
                            }
                            window.location.href = '/payment_success';

                            if (Cookies.get('order_type') === 'premium'){
                                setPremiumstatus(true);
                            }


                        } catch (e) {
                            window.location.href = '/payment_success';
                        }
                    },
                    error: function (data){
                        data = JSON.parse(data.responseText);

                        $('.k_error_mistake').html(data.message);
                        $('.k_error_mistake').fadeIn();
                        $('.threeds_number').next().css('display', 'none');
                    }
                });
            }else{
                window.location.href = '/payment_mistake';
            }
        }else{
            $('.threeds_number').addClass('k_f_error');

            $('.threeds_number').next().html('Не совпадает с отправленным кодом');
            $('.threeds_number').next().fadeIn();
            $('.k_error_mistake').css('display', 'none');
        }

    };

    const setTimerForMessage = () => {
        if (isTimerOn) {
            return
        }
        setCode('');
        setTimerOn(true)
        let a = 45
        const timer = setInterval(() => {
            setTime(--a)
            if (a === 0) {
                setTimerOn(false)
                clearInterval(timer)
                setTime(45)
            }
        }, 1000)
    }

    if (!global_timer_is_run){
        setTimerForMessage();
        global_timer_is_run = true;
    }


    $('body').off('change keyup', '.threeds_number');
    $('body').on('change keyup', '.threeds_number', function (){
        if ($(this).val().replace(' ', '').length >= 5){
            $(this).removeClass('k_f_error');

            $('.style_1_base_button_payment').removeClass('disable');
        }else {
            $(this).addClass('k_f_error');

            $('.style_1_base_button_payment').addClass('disable');
        }
    });

    React.useEffect(() => {
        window.addEventListener("resize", findScreenWidth);
        return () => {
            window.removeEventListener("resize", findScreenWidth);
        };
    }, []);

    return (
        <>
            <form className="payment_form_card" onSubmit={handleSubmit(onSubmit)}>
                <div className="payment_form_card_form payment_form_card_form_3ds">
                    <h3 className="payment_form_3ds_title style_1_caption_18_500">Подтверждение покупки</h3>

                    <div className="payment_form_card_form_input">
                        <label className="payment_form_card_form_input_label style_1_caption_12_500">
                            Код из пуша или СМС
                        </label>
                        <InputMask
                            onFocus={() => {
                                changeState(true);
                            }}
                            className="style_1_base_input threeds_number"
                            value={code}
                            onChange={handleChangeCode}
                            placeholder="00000"
                            mask="99999"
                            maskChar=""
                            aria-invalid={errorForCode ? "true" : "false"}
                        />
                        <span className='style_1_base_input_error' style={{display:'none'}}>Не совпадает с отправленным кодом</span>
                        <span className="k_error k_error_mistake style_1_base_input_error" style={{display:'none'}}>Не совпадает с отправленным кодом</span>
                    </div>
                    <button type='button' onClick={() => setTimerForMessage()} className="style_1_base_link_blue timer_reset">
                        {isTimerOn ? `Отправим код повторно через ${time} секунд` : 'Отправить код повторно'}
                    </button>
                    <button onClick={() => { navigate(-1) }} className="style_1_base_link_blue style_1_base_link_blue_exit" type="button">
                        Отменить
                    </button>
                    <div className="style_1_base_button_payment_body">
                        <button type='submit' className='style_1_base_button_payment disable'>Оплатить</button>
                    </div>
                </div>
            </form>
            <a className='payment_form_card_back_wrapper_cap' href={"/payment/" + (+Cookies.get('user_buy_product') ? +Cookies.get('user_buy_product') : '0')}></a>
        </>
    );
};

export default PaymentForm3DS;