import React, { useState, useEffect } from 'react';
import './Confirmation.css';
import Gradient from "../../../components/Layout/Gradient/Gradient";
import ClassicTitle from "../../../components/PopupsWhite/ClassicTitle/ClassicTitle";

import form_success_icon from "../../../assets/icons/form/form_success_icon.svg"

const ConfirmationPage = ({title, desc_1 = '', desc_2 = '', link_name = 'Войти', link_href = '/', icon_ok = false, add_class = ''}) => {
    return (
        <>
            <Gradient type="gradient_bottom" />
            <section className={"style_1_popup_white_out style_1_popup_all_page " + add_class}>
                <div className="style_1_popup_white_in confirmation">
                    <ClassicTitle title="Битва покемонов" />

                    <div className={"style_1_popup_white_title2" + (icon_ok ? " style_1_popup_white_title_no_link" : "")}>
                        {icon_ok ? <img src={form_success_icon} alt="ок" /> : <a href={link_href} className="style_1_popup_white_title_prev"></a>}
                        <p className="register_title_h2 style_1_caption_16_500 style_1_text_left">{title}</p>
                    </div>

                    <div className="confirmation_body">
                        <p className="confirmation_body_text style_1_caption_14_500 style_1_text_left">
                            {desc_1}
                        </p>
                        <p className="confirmation_body_text style_1_caption_14_500 style_1_text_left">
                            {desc_2}
                        </p>
                    </div>
                    <a className="auth_registr_add style_1_caption_14_500 base_link link_all_orange" href="/login">{link_name}</a>
                </div>
            </section>
        </>
    );
};

export default ConfirmationPage;