import React, { useState, useEffect } from 'react';

import "./DebugMenuPokemon.css";
import none_pokemon_one from "../../../assets/images/none_pokemon_one.png"

import $ from "jquery";
import Cookies from 'js-cookie';

$('body').on('click', '.k_debug_menu_c_delete_o_pokemon', function (){
    var is_delete = $(this).attr('data-is-delete');
    if (is_delete === 0 || is_delete === '') {
        $(this).attr('data-is-delete', 1);
        $(this).html('Вы подтверждаете удаление?');
    }

    if (+is_delete === 1){
        $(this).attr('data-is-delete', 2);
        $(this).html('Это действие не обратимо!');
    }

    if (+is_delete === 2){
        $(this).html('Покемон удален');

        var dataz = {}
        dataz['pokemon_id'] = +$('.info_debug').attr('data-pokemon-id');
        var headers = { 'trainer_token': Cookies.get('session_id') }
        $.ajax({
            url: process.env.REACT_APP_MAIN_PORT_URL + process.env.REACT_APP_MAIN_PORT + '/pokemons',
            type: 'DELETE',
            dataType: 'json',
            headers: headers,
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                setTimeout(function (){
                    Cookies.set('rerender_debug_menu', '1');
                }, 2000);
            }
        });
    }
})

function time_to_animate(thises, start, end, add, start_color, end_color){
    var shag = 0;
    var shag_val = 0;
    var new_shag = 0
    var direct = false;

    if (start > end){
        shag_val = (start - end);
        shag = shag_val/10;
    }else{
        shag_val = (end - start);
        shag = shag_val/10;
        direct = true;
    }

    thises = thises;
    for (var i= 0; i < 11; i++){
        if (direct == true){
            new_shag = (shag*i) + start
        }else{
            new_shag = start - (shag*i)
        }

        setTimeout(function (thises, new_shag, add){
            if (new_shag === 0 || new_shag === 100){
                add = 0;
            }

            thises.css('background', 'linear-gradient(90deg, '+start_color+' '+new_shag+'%, '+end_color+' '+(new_shag+add)+'%)');
        }, (30*i), thises, new_shag, add)
    }
}

$('body').on('click', '.k_debug_pokemon .k_debug_menu_c_kill_pokemon', function (){
    var hp = +$(this).attr('data-hp');
    var hp_add = 15;

    if (!$(this).hasClass('k_alive_pokemon')){
        hp -= 25;

        if (+hp <= 0) {
            $(this).addClass('k_alive_pokemon');
            $(this).html('Нокаут');
            $(this).closest('.k_debug_menu_c_pokemon_active').addClass('k_die')

            var dataz = {}
            dataz['pokemon_id'] = +$('.info_debug').attr('data-pokemon-id');
            var thises_rem = $(this);
            $.ajax({
                url: process.env.REACT_APP_NEW_API + '/debug_menu/knockout_pokemon',
                type: 'POST',
                dataType: 'json',
                headers: { 'trainer_token': Cookies.get('session_id') },
                contentType: "application/json",
                data: JSON.stringify(dataz),
                success: function (data) {
                    $('.k_pok_change_pokeball').addClass('k_die');
                    $('.k_debug_pokemon').addClass('k_die');
                    Cookies.set('rerender_debug_menu', '1');
                }
            });
        }

        time_to_animate($(this), +$(this).attr('data-hp'), hp, hp_add, '#258f25', 'red');
        $(this).attr('data-hp', hp);
    }

})

var s_int = '';
$('body').on('keyup', '.k_debug_pokemon .k_debug_menu_c_input', function () {
    clearTimeout(s_int)
    var thises = $(this);
    s_int = setTimeout(function (thises){
        if (thises.val() !== '') {
            $('.k_main_error_text').remove();
            var dataz = {}
            var headers = {'trainer_token': Cookies.get('session_id')}
            dataz[thises.attr('data-name')] = thises.val();
            dataz['pokemon_id'] = +$('.info_debug').attr('data-pokemon-id');
            $('.k_main_error_text').remove();
            $.ajax({
                url: process.env.REACT_APP_NEW_API + '/debug_menu/update_pokemon',
                type: 'PATCH',
                dataType: 'json',
                headers: headers,
                contentType: "application/json",
                data: JSON.stringify(dataz),
                success: function (data) {
                    if (thises.attr('data-name') === 'attack') {
                        $('.k_cart_trainer_attack').html(thises.val());
                        $('.k_cart_trainer_attack + ul > li').attr('data-active', thises.val());
                        Cookies.set('rerender_debug_menu_attack', thises.val());
                    }

                    if (thises.attr('data-name') === 'stage') {
                        $('.k_cart_trainer_stage').html(thises.val());
                        $('.k_cart_trainer_stage + ul > li').attr('data-active', thises.val());
                        Cookies.set('rerender_debug_menu_stage', thises.val());
                    }

                    if (thises.attr('data-name') === 'photo_id') {
                        if (thises.val() === -1) {
                            $.ajax({
                                url: process.env.REACT_APP_MAIN_PORT_URL + process.env.REACT_APP_MAIN_PORT + '/pokemons?pokemon_id=' + $('.info_debug').attr('data-pokemon-id') + '&page=0',
                                type: 'GET',
                                dataType: 'json',
                                headers: { 'trainer_token': Cookies.get('session_id') },
                                async: false,
                                success: function (data) {
                                    if (data.photo_id) {
                                        data.photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(data.photo_id+'').padStart(3, '0')+'.png';
                                    } else {
                                        data.photo = none_pokemon_one;
                                    }
                                }
                            })
                        }

                        Cookies.set('rerender_debug_menu', '1');
                    } else {
                        $('.k_cart_trainer_' + thises.attr('data-name')).html(thises.val());
                        Cookies.set('rerender_debug_menu_name', '.k_cart_trainer_' + thises.attr('data-name') + '::' + thises.val());
                    }
                },
                error: function (data) {
                    data = JSON.parse(data.responseText);

                    thises.after('<font class="k_main_error_text k_f_error">' + data.message + '</font>')
                }
            });
        }

        clearTimeout(s_int);
    }, 1200, thises)

})

$('body').on('click', '.k_debug_pokemon .k_debug_menu_c_pokemon_pokeball_change', function (){
    var dataz = {}
    dataz['pokemon_id'] = +$('.info_debug').attr('data-pokemon-id');
    dataz['trainer_id'] = +$('.info_debug').attr('data-trainer-id');

    if (+$(this).attr('data-status') === 1){
        $('.k_debug_menu_c_pokemon_pokeball_status').html('Не привязан к покетболу');
        $(this).html('Привязать');
        $(this).attr('data-status', 0);

        $.ajax({
            url: process.env.REACT_APP_NEW_API + '/debug_menu/delete_pokeball',
            type: 'POST',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                Cookies.set('rerender_debug_menu', '1');
            }
        });
    }else{
        $('.k_debug_menu_c_pokemon_pokeball_status').html('Привязан к покетболу');
        $(this).html('Отвязать');
        $(this).attr('data-status', 1)

        $.ajax({
            url: process.env.REACT_APP_NEW_API + '/debug_menu/add_pokeball',
            type: 'POST',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                Cookies.set('rerender_debug_menu', '1');
            }
        });
    }
})

const DebugMenuPokemon = ({pokemon}) => {
    $('header').css('display', 'none');

    return (
        <main className="content his-open container info_debug" data-pokemon-id={pokemon.id} data-trainer-id={pokemon.trainer_id}>
            <div className='k_debug_menu'>
                <div className='k_debug_menu_console k_debug_pokemon'>
                    <div className='k_debug_menu_c_title'>Редактировать покемона</div>

                    <div className='k_debug_menu_c_flex'>
                        <label>Имя</label>
                        <input type='text' data-name='name'
                               className='k_debug_menu_c_input k_debug_menu_c_pokemon_name'
                               defaultValue={pokemon.name}/>
                    </div>

                    <div className='k_debug_menu_c_flex'>
                        <label>Атака</label>
                        <input type='number' data-name='attack'
                               className='k_debug_menu_c_input k_debug_menu_c_pokemon_attack'
                               defaultValue={pokemon.attack}/>
                    </div>

                    <div className='k_debug_menu_c_flex'>
                        <label>Стадия</label>
                        <input type='number' data-name='stage'
                               className='k_debug_menu_c_input k_debug_menu_c_pokemon_stage'
                               defaultValue={pokemon.stage}/>
                    </div>

                    <div className='k_debug_menu_c_flex'>
                        <label>Картинка(ID)</label>
                        <input type='number' data-name='photo_id'
                               className='k_debug_menu_c_input k_debug_menu_c_pokemon_photo' defaultValue={pokemon.photo_id}/>
                    </div>

                    <div className='k_debug_menu_c_flex k_pok_change_pokeball'>
                        <div className='k_debug_menu_c_pokemon_pokeball_status'>Не привязан к покетболу</div>
                        <div className='k_debug_menu_c_b k_debug_menu_c_pokemon_pokeball_change'>Привязать
                        </div>
                    </div>

                    <div className='k_debug_menu_c_b k_debug_menu_c_kill_pokemon' data-hp='100'>В нокаут</div>
                </div>
            </div>
        </main>
    );
};

export default DebugMenuPokemon;