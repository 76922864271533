import React, { useState } from 'react';
import "./Profile.css";

import base_arrow_left from "../../assets/icons/base_arrow_left.svg";

import Cookies from 'js-cookie';

import $ from "jquery";
import {useAuth} from "../UserCore/Auth/AuthContext";
import BackButton from "../../components/Interactive/BackButton/BackButton";
import {useNavigate} from "react-router-dom";

import settings_token_icon from "../../assets/icons/settings/settings_token_icon.svg"
import InputPassword from "../../components/PopupsWhite/InputPassword/InputPassword";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {TextField} from "@mui/material";

function k_get(name){
    if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
        return decodeURIComponent(name[1]);
}

if (k_get('connect_trainer_page')){
    const url = new URL(document.location);
    const searchParams = url.searchParams;
    searchParams.delete("connect_trainer_page");
    window.history.pushState({}, '', '/trainer/'+Cookies.get('user_id'));
    window.history.pushState({}, '', url.toString());
}

const ProfilePage = () => {
    const { user, UpdateUserField, updateUserData } = useAuth();
    const [showFormAddMail, setShowFormAddMail] = useState(false);
    let navigate = useNavigate();

    React.useEffect(() => {
        if (k_get('hash')){
            $.ajax({
                url: process.env.REACT_APP_NEW_API + '/technical_routes/auth_control',
                type: 'POST',
                dataType: 'json',
                data: {action: 'email_check', trainer_id: k_get('trainer_id'), client_key: k_get('hash')},
                success: function (data) {
                    if (data.message === 'Успешно') {
                        $('.k_pochta_success').css('display', 'block');
                        $('.k_pochta_pass').html(data.password);

                        UpdateUserField('email_confirm', true);

                        const url = new URL(document.location);
                        const searchParams = url.searchParams;
                        searchParams.delete("hash");
                        searchParams.delete("trainer_id");
                        window.history.pushState({}, '', '/trainer/'+Cookies.get('user_id'));
                        window.history.pushState({}, '', url.toString());
                    }
                },
                error: function (data){
                    data = JSON.parse(data.responseText);
                    $('.k_pochta_error .k_pochta_error_m').html(data.message);
                    $('.k_pochta_error').css('display', 'block');

                    k_render_profile();
                }
            });
        }else{
            k_render_profile();
        }

        if (user.email_confirm === false || user.email_confirm === 'false'){
            updateUserData()
        }
    }, []);

    React.useEffect(() => {
        k_render_profile();
    }, [user]);

    $('body').off('click', '.k_send_mail_renew');
    $('body').on('click', '.k_send_mail_renew', function (){
        $('.k_profile_email_confirm').css('display', 'none');
        setShowFormAddMail(true);

        return false;
    });

    $('body').off('click', '.k_google_connect .delete_method');
    $('body').on('click', '.k_google_connect .delete_method', function (){
        $.ajax({
            url: process.env.REACT_APP_NEW_API + '/technical_routes/auth_control',
            type: 'POST',
            dataType: 'json',
            data: {action: 'delete_vk', trainer_token: Cookies.get('session_id')},
            success: function (data) {
                if (data.status == 'success') {
                    UpdateUserField('vk_auth_id', null);
                }
            }
        });
    });

    Cookies.set('K_global_gets_trainer', 0, { expires: 365 });

    function k_render_profile(){
        $('body').css('visibility', 'visible');

        Cookies.set('user_auth', '1', { expires: 365 });

        setTimeout(function (){
            var url_to_google = 'https://id.vk.com/auth?uuid='+process.env.REACT_APP_TECH_API_VK_SECRET_PUBLIC_HASH+'&app_id='+process.env.REACT_APP_TECH_API_VK_APP_ID+'&response_type=silent_token&redirect_uri='+process.env.REACT_APP_NEW_API+'/technical_routes/callback_vk';

            var none_p = '<p class="profile__subtitle text-center">Невозможно отвязать<br /> единственный способ</p>';
            var button_disconnect = '<div type="submit" class="profile__button profile__button-right profile__button-light delete_method">Отвязать</div>';
            var button_connect = '<div type="submit" class="profile__button profile__button-right">Привязать</div>';

            $('.k_google_connect').html('')
            $('.k_email_connect').html('')

            var max_methods = 0;
            if (user.login && user.login !== 'null' && user.email_confirm && user.email_confirm !== 'null' && user.email_confirm !== 'false'){
                max_methods += 1;
            }

            if (user.vk_auth_id && user.vk_auth_id !== 'null'){
                max_methods += 1;
            }

            if (user.vk_auth_id && user.vk_auth_id !== 'null'){
                $('.k_google_name').html(user.vk_auth_id);
                $('.k_google_name').removeClass('profile__text-grey');
                $('.k_google_connect').html('');

                if (max_methods <= 1) {
                    $('.k_google_connect').append(none_p)
                }else{
                    $('.k_google_connect').append(button_disconnect)
                }
            }else{
                $('.k_google_name').html('Не привязан');
                $('.k_google_name').addClass('profile__text-grey');
                $('.k_google_connect').html('');
                $('.k_google_connect').append(button_connect)
                $('.k_google_connect > div').off('click');
                $('.k_google_connect > div').on('click', function (){
                    window.location.href = url_to_google;
                });
                Cookies.set('google_add', 'true', { expires: 100 });
            }

            if (user.login && user.login !== 'null' && user.email_confirm && user.email_confirm !== 'null' && user.email_confirm !== 'false'){
                $('.k_email_name').html(user.login);
                $('.k_email_name').removeClass('profile__text-grey');
                $('.k_email_connect').html('');
                $('.k_email_connect').append('<p class="profile__subtitle text-center">Невозможно отвязать</p>');

                $('.k_profile_email_ok').css('display', 'flex');
                $('.k_profile_email_confirm').css('display', 'none');
            }else{
                $('.k_email_name').html('Не привязан');
                $('.k_email_name').addClass('profile__text-grey');

                if (user.email_confirm === false || user.email_confirm === 'false' || user.email_confirm === 'null' && user.login && user.login !== 'null'){
                    setShowFormAddMail(false)
                    $('.k_profile_email_confirm').css('display', 'block');
                }else{
                    $('.k_profile_email_confirm').css('display', 'none');
                    setShowFormAddMail(true)
                }

                $('.k_profile_email_ok').css('display', 'none');
            }

            if (k_get('error')){
                $('.k_pochta_error .k_pochta_error_m').html('Привязка аккаунта<br>'+k_get('message'));
                $('.k_pochta_error').css('display', 'block');

                const url = new URL(document.location);
                const searchParams = url.searchParams;
                searchParams.delete("error");
                searchParams.delete("message");
                window.history.pushState({}, '', url.toString());
            }
        }, 500);
    }

    function copy_number_f(event){
        var el = $(event.target).closest('.copy_number');
        var el_id = $(el).find('.copy_number_id');
        var el_button = $(el).find('.copy_number_button');

        event.stopPropagation();
        if (el.attr('data-block') !== 'true') {
            navigator.clipboard.writeText(el_id.text()).then(function() {
                console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });

            setTimeout(function ( el_button, el ) {
                el_button.css('opacity', '0.3');
                el.attr('data-block', 'true');
            }, 50, el_button, el);

            setTimeout(function ( el_button ) {
                el_button.css({'opacity': '0.5', 'scale': '0.5'});
            }, 100, el_button);

            setTimeout(function ( el_button ) {
                el_button.css({'opacity': '1', 'scale': '1'});
            }, 200, el_button);

            setTimeout(function ( el_id ) {
                el_id.fadeOut(100);
            }, 300, el_id);

            setTimeout(function ( el ) {
                if (el.attr('data-last') === '' || !el.attr('data-last')) {
                    el_id.attr('data-last', el_id.text());
                    el_id.text('Скопировано');
                }

                el_id.fadeIn(300);
            }, 400, el, el_id);

            setTimeout(function ( el_id ) {
                el_id.fadeOut(100);
            }, 1000, el_id);

            setTimeout(function ( el_id, el ) {
                el_id.fadeIn(300);
                el_id.text(el_id.attr('data-last'));
                el_id.attr('data-last', '');
                el.attr('data-block', 'false');
            }, 1100, el_id, el);
        }
    }

    return (
        <div className="profile_page">
            <main className="content">
                <div className="gradient auth_gradient"></div>
                <BackButton />
                <section className="auth profile" style={{'display': 'flex'}}>
                    <div className="login__content profile">
                        <div className="profile-mobile">
                            <div onClick={() => navigate(-1)} className="profile__back-button-mobile">
                                <img src={base_arrow_left} alt="вернуться назад" />
                            </div>
                            <h2 className="profile__title-mobile">Настройки входа</h2>
                        </div>

                        <h1 className="profile__title">Настройки входа</h1>

                        <div className="profile__container p-22 k_pochta_success" style={{ display: "none" }}>
                            <div>
                                <div className="k_pochta_ok">Почта успешно подтверждена</div>
                            </div>
                        </div>

                        <div className="profile__container p-22 k_pochta_error" style={{ display: "none" }}>
                            <div>
                                <div className="k_pochta_error_m"></div>
                            </div>
                        </div>

                        <Formik
                            initialValues={{
                                password_1: '',
                                password_2: '',
                                email: '',
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[a-zA-Z]{2,}$/i, 'Введите корректную почту').matches(/^\S+$/i, 'Введите корректную почту').max(255).required('Введите почту'),
                                password_2: Yup.string().oneOf([Yup.ref('password_1'), null], 'Пароли не совпадают').max(255).required('Пароли не совпадают'),
                                password_1: Yup.string().matches(/^[A-Z0-9._%+#$!@-]{2,}$/i, 'Пароль не соответствует требованиям.').matches(/^\S+$/i, 'Пароль не соответствует требованиям.').matches(/(?=.*[A-Z])(?=.*\d)/, 'Пароль не соответствует требованиям.').min(6, 'Пароль не соответствует требованиям.').max(255).required('Введите пароль'),
                            })}
                            onSubmit={async (values, {setErrors}) => {
                                let k_error = 0;

                                if ( k_error === 0) {
                                    $.ajax({
                                        url: process.env.REACT_APP_NEW_API + '/technical_routes/auth_control',
                                        type: 'POST',
                                        dataType: 'json',
                                        data: {action: 'email_send_profile', email: values['email'], trainer_token: Cookies.get('session_id'), trainer_id: Cookies.get('user_id'), pass_1: values['password_1'], pass_2: values['password_2']},
                                        success: function (data) {
                                            if (data.message === 'Успешно') {
                                                UpdateUserField('login', $('#k_email').val());
                                                UpdateUserField('email_confirm', false);
                                            }
                                        },
                                        error: function (data){
                                            data = JSON.parse(data.responseText);

                                            var errors = [];
                                            var show_pass = false;

                                            if(data.message === 'Аккаунт с такой почтой уже зарегистрирован' || data.message === "Почта занята"){
                                                errors['email'] = 'Аккаунт с такой почтой уже зарегистрирован';

                                                show_pass = true;
                                            }

                                            if(show_pass === false){
                                                errors['password'] = data.responseText;
                                            }

                                            setErrors(errors);
                                        }
                                    });
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
                                <Form className="auth__form profile__email-f profile__container k_profile_email_register" style={!showFormAddMail ? { display: "none" } : {}}>
                                    <h4 className="profile__label">Почта и пароль</h4>
                                    <p className="profile__subtitle mb-16">
                                        Потребуется подтверждение. <br /> После подтверждения невозможно отвязать
                                    </p>

                                    <TextField
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        label="Почта"
                                        margin="normal"
                                        name="email"
                                        id="k_email"
                                        autoComplete="off"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.email}
                                        variant="outlined"
                                        className='k_form_control'
                                    />

                                    <div className="df register__df">
                                        <InputPassword showPassHelp={true} label="Пароль" name="password_1" onBlur={handleBlur} onChange={handleChange} touched={touched.password_1} error={errors.password_1} value={values.password_1} />
                                        <InputPassword label="Повторите пароль" name="password_2" onBlur={handleBlur} onChange={handleChange} touched={touched.password_2} error={errors.password_2} value={values.password_2} />
                                    </div>
                                    <button type="submit" className="profile__button profile__button-right k_send_mail_confirm">
                                        Привязать
                                    </button>
                                </Form>
                            )}
                        </Formik>

                        <form action="#" className="auth__form profile__email-f profile__container k_profile_email_confirm" noValidate={true} style={{ display: "none" }}>
                            <h4 className="profile__label">Почта и пароль</h4>
                            <p className="profile__subtitle mb-16">
                                Мы отправили подтверждение на вашу почту. Чтобы завершить, перейдите по ссылке в письме
                            </p>
                            <p className="profile__subtitle mb-16">
                                Нет письма? Проверьте папку «Спам»
                            </p>
                            <div className="auth__wrap" style={{ opacity: "0.6" }}>
                                <input type="email" value={user.login} disabled="true" className="auth__input profile__input k_input_mail_renew" placeholder=" "/>
                                <label className="auth__label">Почта</label>
                                <span className="auth__error" style={{ display: "none" }}>Введите почту</span>
                            </div>
                            <button type="submit" className="profile__button profile__button-right k_send_mail_renew">
                                Изменить
                            </button>
                        </form>

                        <div className="profile__container p-22 flex k_profile_email_ok">
                            <div>
                                <p className="profile__title-grey">Почта</p>
                                <div className="profile__text profile__text-grey k_email_name k_profile_title_left">Не привязан</div>
                            </div>

                            <div className='k_email_connect'></div>
                        </div>

                        <div action="#" className="profile__container p-22 flex">
                            <div>
                                <p className="profile__title-grey">VK</p>
                                <div className="profile__text profile__text-grey k_google_name k_profile_title_left">Не привязан</div>
                            </div>

                            <div className='k_google_connect'></div>
                        </div>
                        <div className="k_cart_trainer_export k_cart_trainer_export_mobile" style={{'display': 'none'}}>
                            <img src={settings_token_icon} className="k_cart_trainer_export_icon" />
                            <div className="k_cart_trainer_export_box">
                                <div className="k_cart_trainer_export_box_title style_1_caption_16_400 style_1_text_left">Мой токен</div>
                                <div className="k_cart_trainer_export_box_desc style_1_caption_12_400 style_1_text_left">
                                    <div className="copy_number" onClick={copy_number_f}>
                                        <div className="copy_number_id">{Cookies.get('session_id')}</div>
                                        <button className="copy_number_button"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="k_cart_trainer_export">
                        <img src={settings_token_icon} className="k_cart_trainer_export_icon" />
                        <div className="k_cart_trainer_export_box">
                            <div className="k_cart_trainer_export_box_title style_1_caption_16_400 style_1_text_left">Мой токен</div>
                            <div className="k_cart_trainer_export_box_desc style_1_caption_12_400 style_1_text_left">
                                <div className="copy_number" onClick={copy_number_f}>
                                    <div className="copy_number_id">{Cookies.get('session_id')}</div>
                                    <button className="copy_number_button"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default ProfilePage;