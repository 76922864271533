import React, {useState} from 'react';
import {usePokemonOne} from "./PokemonOneContext";
import "./PokemonOne.css";

import CopyNumber from "../../components/Interactive/CopyNumber/CopyNumber";
import BackButton from "../../components/Interactive/BackButton/BackButton";

import base_pokeball_icon from "../../assets/icons/base_pokeball_icon.svg"
import base_super_sline_icon from "../../assets/icons/base_super_sline_icon.svg"
import base_skull_icon from "../../assets/icons/base_skull_icon.svg"

import none_pokemon_one from "../../assets/images/none_pokemon_one.png"
import none_trainer_one from "../../assets/images/none_trainer_one.png"

import base_arrow_left from "../../assets/icons/base_arrow_left.svg";
import PokemonHistoryList from "../../components/Pokemons/PokemonHistoryList/PokemonHistoryList";
import {generatePokemonImageUrl, generateTrainerImageUrl, kGetUrl} from "../../helpers/helpers";
import LevelOrAttack from "../../components/Interactive/LevelOrAttack/LevelOrAttack";
import $ from "jquery";
import {useNavigate, useParams} from "react-router-dom";
import DebugMenuTrainer from "../../components/Pokemons/DebugMenuTrainer/DebugMenuTrainer";
import DebugMenuPokemon from "../../components/Pokemons/DebugMenuPokemon/DebugMenuPokemon";
import Cookies from "js-cookie";
import base_cup_icon from "../../assets/icons/base_cup_icon.png";
import base_premium_icon from "../../assets/icons/base_premium_icon.png";

const PokemonOnePage = () => {
    const [isAllowRender, setisAllowRender] = useState(false);
    const [isAllowRenderTrainerCard, setIsAllowRenderTrainerCard] = useState(false);
    const [isAllowRenderHistoryList, setIsAllowRenderHistoryList] = useState(false);

    const { pokemon, trainer, getAllInfo, pokemonHistory } = usePokemonOne();

    const navigate = useNavigate();
    const params = useParams();
    var pokemon_id = params.pokemonId;

    React.useEffect(() => {
        setisAllowRender(false)
        setIsAllowRenderTrainerCard(false)

        const fetchData = async () => {
            await getAllInfo().then(function (){
                setisAllowRender(true)
            })
        }

        fetchData();
    },[params.pokemonId]);

    React.useEffect(() => {
        if (kGetUrl('debug_trainer_active') !== "1") {
            setInterval(function (){
                if (Cookies.get('rerender_debug_menu') === '1') {
                    Cookies.set('rerender_debug_menu', '0');
                    window.location.reload();
                }

                if (Cookies.get('rerender_debug_menu_attack') && Cookies.get('rerender_debug_menu_attack') !== 'false'){
                    window.location.reload();
                    Cookies.set('rerender_debug_menu_attack', 'false');
                }
                if (Cookies.get('rerender_debug_menu_stage') && Cookies.get('rerender_debug_menu_stage') !== 'false'){
                    window.location.reload();
                    Cookies.set('rerender_debug_menu_stage', 'false');
                }
                if (Cookies.get('rerender_debug_menu_name') && Cookies.get('rerender_debug_menu_name') !== 'false'){
                    window.location.reload();
                    Cookies.set('rerender_debug_menu_name', 'false');
                }
            }, 500)
        }
    }, [])

    if (pokemon.id === pokemon_id && trainer.id === pokemon.trainer_id && !isAllowRenderTrainerCard){
        setIsAllowRenderTrainerCard(true);
    }

    if (pokemon.id === pokemon_id && !isAllowRenderHistoryList){
        if (pokemonHistory[0]) {
            if (+pokemonHistory[0]['my']['id'] === +pokemon_id) {
                setIsAllowRenderHistoryList(true);
            }
        } else{
            setIsAllowRenderHistoryList(true);
        }
    }


    const handleLinkTrainer = (e) => {
        var el = $(e.target).closest('.pokemon_one_body_content_inner_trainer_info');

        navigate('/trainer/'+el.attr('data-id')+'/', {replace: false});
    };

    return (
        <>
            { (kGetUrl('debug_trainer_active') !== "1") ?
                <div className="single_page">
                    <div className="single_page_body">
                        <BackButton />
                        <div className="single_page_body_avatar">
                            <div className="pokemon_one_body_avatar_gradient" style={{"left": "-46.5px"}}></div>
                            <img className={"pokemon_one_body_avatar_img" + (isAllowRender && pokemon.status !== 1 ? ' die' : '')} src={isAllowRender ? generatePokemonImageUrl(pokemon.photo_id) : none_pokemon_one} alt="image" />
                        </div>
                        <div className="single_page_body_content">
                            <div className="single_page_body_content_title">
                                <p className="pokemon_one_body_content_title_text style_1_caption_16_500 style_1_text_left">
                                    {pokemon.legend ? 'Легендарный покемон' : 'Покемон'}
                                </p>
                                <div className="pokemon_one_body_content_title_icons">
                                    <h2 className="pokemon_one_body_content_title_icons_name style_1_heading_38_400_pokemon_classic style_1_text_left">{isAllowRender ? pokemon.name : '...... .....'}</h2>
                                    {pokemon.legend && isAllowRender ? <div className={"pokemon_one_body_content_title_icon pokemon_one_body_content_title_icon_gold" + (pokemon.status !== 1 && isAllowRender ? " die" : "")}>
                                        <img src={base_super_sline_icon} className="pokemon_one_body_content_title_icon_img" alt="иконка золотая" />
                                    </div> : ''}
                                    {pokemon.status !== 1 && isAllowRender ? <div className="pokemon_one_body_content_title_icon pokemon_one_body_content_title_icon_poketball">
                                        <img src={base_skull_icon} className="pokemon_one_body_content_title_icon_img" alt="иконка черепа" />
                                    </div> : ''}
                                    {pokemon.in_pokeball && isAllowRender ? <div className="pokemon_one_body_content_title_icon pokemon_one_body_content_title_icon_poketball">
                                        <img src={base_pokeball_icon} className="pokemon_one_body_content_title_icon_img" alt="иконка покеболла" />
                                    </div> : ''}
                                </div>
                            </div>
                            <div className="single_page_body_content_inner">
                                <div className="pokemon_one_body_content_inner_top">
                                    <div className="pokemon_one_body_content_inner_top_list_attr pokemon_one_body_content_inner_box">
                                        <div className="pokemon_one_body_content_inner_top_list_attr_one">
                                            <span className="pokemon_one_body_content_inner_top_list_attr_one_name">ID покемона</span>
                                            <div className="pokemon_one_body_content_inner_top_list_attr_one_text">
                                                <CopyNumber id={isAllowRender ? pokemon.id : '....'} />
                                            </div>
                                        </div>
                                        <div className="pokemon_one_body_content_inner_top_list_attr_one">
                                            <span className="pokemon_one_body_content_inner_top_list_attr_one_name">Стадия</span>
                                            <span className="pokemon_one_body_content_inner_top_list_attr_one_text">{isAllowRender ? pokemon.stage : '.'}</span>
                                            <ul className="pokemon_one_body_content_inner_top_list_attr_one_slide">
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.stage : 0} data-full="3"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.stage : 0} data-full="3"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.stage : 0} data-full="3"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.stage : 0} data-full="3"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.stage : 0} data-full="3"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.stage : 0} data-full="3"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.stage : 0} data-full="3"></li>
                                            </ul>
                                        </div>
                                        <div className="pokemon_one_body_content_inner_top_list_attr_one">
                                            <span className="pokemon_one_body_content_inner_top_list_attr_one_name">Атака</span>
                                            <span className="pokemon_one_body_content_inner_top_list_attr_one_text">{isAllowRender ? pokemon.attack : '.'}</span>
                                            <ul className="pokemon_one_body_content_inner_top_list_attr_one_slide">
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.attack : 0} data-full="7"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.attack : 0} data-full="7"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.attack : 0} data-full="7"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.attack : 0} data-full="7"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.attack : 0} data-full="7"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.attack : 0} data-full="7"></li>
                                                <li className="pokemon_one_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? pokemon.attack : 0} data-full="7"></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div data-id={isAllowRender ? trainer.id : '.'} onClick={handleLinkTrainer} className="pokemon_one_body_content_inner_trainer_info pokemon_one_body_content_inner_box">
                                        <div className="pokemon_one_body_content_inner_trainer_info_avatar">
                                            <img className="pokemon_one_body_content_inner_trainer_info_avatar_img" src={isAllowRenderTrainerCard && trainer.avatar_id ? generateTrainerImageUrl(trainer.avatar_id) : none_trainer_one} alt="Аватар тренера" />
                                        </div>
                                        <div className="pokemon_one_body_content_inner_trainer_info_right">
                                            <div className="pokemon_one_body_content_inner_trainer_info_avatar_right_top">
                                                <h4 className="pokemon_one_body_content_inner_trainer_info_avatar_right_top_title style_1_caption_16_400">Тренер</h4>
                                                <span className="pokemon_one_body_content_inner_trainer_info_avatar_right_top_subtitle style_1_caption_16_400">{isAllowRenderTrainerCard && trainer.trainer_name ? trainer.trainer_name : '...... .....'}</span>
                                                {trainer.level >= 5 ? <div className="single_page_body_content_title_icon single_page_body_content_title_icon_cup">
                                                    <img src={base_cup_icon} className="single_page_body_content_title_icon_img" alt="иконка кубка" />
                                                </div> : ''}
                                                {trainer.is_premium ? <div className="single_page_body_content_title_icon pokemon_one_body_content_title_icon_gold">
                                                    <img src={base_premium_icon} className="single_page_body_content_title_icon_img" alt="иконка премиума" />
                                                </div> : ''}
                                            </div>
                                            <div className="pokemon_one_body_content_inner_trainer_info_avatar_right_bottom">
                                                <LevelOrAttack value={isAllowRenderTrainerCard && trainer.level ? trainer.level : '.'} type='level' />
                                                <CopyNumber id={isAllowRenderTrainerCard ? pokemon.trainer_id : '....'} />
                                            </div>
                                        </div>
                                        <button id="dropdown" className="pokemon_one_body_content_inner_trainer_info_avatar_right_bottom_icon_right">
                                            <img src={base_arrow_left} width="26" height="26" alt="тренер покемона" />
                                        </button>
                                    </div>
                                </div>
                                <div className="k_mobile k_content_trainer">
                                    <PokemonHistoryList history_list={pokemonHistory} pokemon={pokemon} is_allow_render={isAllowRenderHistoryList} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ""
            }

            { (kGetUrl('debug_trainer_active') === "1") ?
                <>
                    <DebugMenuPokemon pokemon={isAllowRender ? pokemon : []}></DebugMenuPokemon>
                </>
                : ""
            }
        </>
    );
};

export default PokemonOnePage;