import React from 'react';
import PropTypes from 'prop-types';

import './InputPassword.css';
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const InputPassword = ({name, label, value, touched, error, onBlur=function (){}, onChange=function (){}, showPassHelp=false}) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl className='k_form_control' fullWidth error={Boolean(touched && error)}>
            <InputLabel htmlFor={"k_"+name}>{label}</InputLabel>
            <OutlinedInput
                id={"k_"+name}
                type={showPassword ? 'text' : 'password'}
                value={value}
                name={name}
                className={"k_"+name}
                autoComplete="off"
                onBlur={onBlur}
                onChange={onChange}
                label={label}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge={"end" + (showPassword ? ' active' : '')}
                            size="large"
                            style={value.length > 0 ? {'display': 'block'} : {'display': 'none'}}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {touched && error && (
                <FormHelperText error id="standard-weight-helper-text">
                    {' '}
                    {error}{' '}
                </FormHelperText>
            )}
            {showPassHelp ? <p className="k_password_info style_1_caption_12_500 style_1_text_left" aria-invalid="false">
                Минимум 6&nbsp;символов, только латиница, 1&nbsp;заглавная буква и&nbsp;1&nbsp;цифра
            </p> : ''}
        </FormControl>
    );
};

InputPassword.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string
};

export default InputPassword;