import React from 'react';

import "./PokemonPokemonList.css";
import {generatePokemonImageUrl, trainer_or_pokemons_open_lists} from "../../../helpers/helpers";
import CopyNumber from "../../Interactive/CopyNumber/CopyNumber";
import $ from "jquery";
import {useNavigate, useParams} from "react-router-dom";

import base_pokemons_icon from "../../../assets/icons/base_pokemons_icon.svg"
import base_arrow_left from "../../../assets/icons/base_arrow_left.svg";
import base_close_icon from "../../../assets/icons/base_close_icon.svg";

import base_skull_icon from "../../../assets/icons/base_skull_icon.svg"
import base_pokeball_icon from "../../../assets/icons/base_pokeball_icon.svg"
import LevelOrAttack from "../../Interactive/LevelOrAttack/LevelOrAttack";

const PokemonPokemonList = ({pokemons, pokemonsCount = -1, isNextPage = false, load_trainer_pokemons = function () {}}) => {
    const [PokemonListOpen, SetPokemonListOpen] = React.useState(false);
    const [PokemonListHeight, SetPokemonListHeight] = React.useState('400px');
    const [PokemonListActive, SetPokemonListActive] = React.useState(false);
    const [page, SetPage] = React.useState(1);
    const params = useParams();

    const navigate = useNavigate();

    const handleClick = () => {
        SetPokemonListActive(true);
        SetPokemonListOpen(!PokemonListOpen);

        if (PokemonListOpen) {
            $('.trainer-item .content__inner').css('gap', '');

            $('section.trainer-item.single-trainer').css('margin-bottom', '80px')
            trainer_or_pokemons_open_lists(2)

        } else {
            if(window.innerWidth > 768) {
                var content_h = trainer_or_pokemons_open_lists(1);

                SetPokemonListHeight(content_h);
            }

            return false
        }
    };

    const handleLinkPokemon = (e) => {
        var el = $(e.target).closest('.single_page_pokemons_list_link');

        navigate('/pokemon/'+el.attr('data-id')+'/');
    };

    var max_id_pok = pokemons.length+10;

    const handleClickNextPage = () => {
        load_trainer_pokemons({trainer_id: params.trainerId, page: page+1});

        SetPage(page+1);
    };

    return (
        <div className={"pokemon_one_body_content_inner_pokemons " + ((pokemonsCount > 0 && pokemonsCount !== -1) || (pokemons.length > 0) ? '' : " feature-empty") + (!PokemonListOpen ? "" : " down")}>
            <a className="pokemon_one_body_content_inner_hystory_battle_link style_1_caption_16_400 pokemon_one_body_content_inner_box" onClick={handleClick}>
                <img src={base_pokemons_icon} width="24" height="24" alt="история битв" />
                <div className="history-info">
                    Покемоны <span className="total-count history-info_count">{(pokemonsCount > 0 && pokemonsCount !== -1) ? pokemonsCount : ((pokemons.length > 0) ? pokemons.length : 0)}</span>
                </div>
                <button id="dropdown" className={"pokemon_one_body_content_inner_hystory_battle_link_button" + (!PokemonListOpen ? "" : " down")}>
                    <img src={!PokemonListOpen ? base_arrow_left: base_close_icon} width="26" height="26" alt="история битв" />
                </button>
            </a>
            <div className="pokemon_one_body_content_inner_info_mobile">
                <button className="pokemon_one_body_content_inner_info_mobile_button" onClick={handleClick}>
                    <img alt="Стрелка влево" src={base_arrow_left}/>
                </button>
                <div className="pokemon_one_body_content_inner_info_mobile_title style_1_caption_16_500">Покемоны</div>
            </div>
            <div className={"history_battles" + (PokemonListOpen ? ` down` : ``)} style={{'height': PokemonListHeight+'px'}}>
                {pokemons ? pokemons.map((pokemon, index) => (
                    <div className={"single_page_pokemons_list"+(pokemon.status === 0 ? ' feature-empty' : '')}>
                        <div className="single_page_pokemons_list_link" data-id={pokemon.id} onClick={handleLinkPokemon}>
                            <img className="single_page_pokemons_list_link_img" src={generatePokemonImageUrl(pokemon.photo_id)} alt="" />
                            <div className="single_page_pokemons_list_link_content">
                                <div className="single_page_pokemons_list_link_content_title style_1_text_left style_1_caption_16_500">{pokemon.name}</div>
                                <div className="single_page_pokemons_list_link_content_icons">
                                    {pokemon.in_pokeball ?
                                    <div className="single_page_pokemons_list_link_content_icon single_page_pokemons_list_link_content_icon_pokeball">
                                        <img src={base_pokeball_icon} className="single_page_pokemons_list_link_content_icon_img" alt="иконка покебола" />
                                    </div> : ''}
                                    {pokemon.status === 0 ?
                                    <div className="single_page_pokemons_list_link_content_icon single_page_pokemons_list_link_content_icon_skill">
                                        <img src={base_skull_icon} className="single_page_pokemons_list_link_content_icon_img" alt="иконка смерти" />
                                    </div> : '' }
                                    <LevelOrAttack value={pokemon.attack} />
                                </div>
                                <CopyNumber id={pokemon.id} />
                            </div>
                        </div>
                    </div>
                    )) : ''}
                {isNextPage && pokemonsCount !== false ? <div className="k_load_more" onClick={handleClickNextPage}>
                    <button className="style_1_caption_16_500 style_1_button_background">Загрузить еще</button>
                </div> : ''}
            </div>
        </div>
    );
};

export default PokemonPokemonList;