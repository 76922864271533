import React from 'react';
import PropTypes from 'prop-types';

import './SendButtonStandart.css';
import {Box, Button} from "@mui/material";

const SendButtonStandart = ({title, is_disabled, className=''}) => {
    return (
        <>
                <Button className={'style_1_popup_white_base_button_orange style_1_caption_14_500' + (className !== '' ? ' '+className : '')} color="primary" fullWidth size="large" type="submit" variant="contained">
                    {title}
                </Button>
        </>
    );
};

SendButtonStandart.propTypes = {
    title: PropTypes.string,
    is_disabled: PropTypes.bool
};

export default SendButtonStandart;