import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";

const RegistrationVKContext = createContext(undefined);

export const RegistrationVKProvider = ({ children }) => {
    const authVK = async () => {
        const asyncFn = async () => {
            var hash = window.location.pathname.split('/')[2];

            await axios.post(`${process.env.REACT_APP_NEW_API}/technical_routes/auth_control`, {
                action: 'auth',
                api_key: hash
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            }).then(function (response) {
                if (response.status === 200) {
                    Cookies.set('user_id', response.data.id, {expires: 365});
                    Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + response.data.avatar_id).padStart(2, '0')+'.png', {expires: 365})

                    window.location.href = '/';
                }
            }).catch(function (response){
                var data = response.response.data;
                $('.style_1_popup_vk_token').css('visibility', 'visible');
                $('span.auth__error').html(data.message);
                $('span.auth__error').fadeIn();
                $('.k_form_reg_g_token').attr('aria-invalid', "true");

                setTimeout(function () {
                    $('.k_form_hidden').css('visibility', 'visible');
                }, 700);
            })
        }

        await asyncFn();
    };

    const registerVK = async (values, {setErrors}) => {
        var hash = window.location.pathname.split('/')[2];
        var token_go = values['token'];

        var formData = new FormData();
        formData.append('trainer_token', values['token']);
        formData.append('secure_hash', hash.split('_-_')[1]);
        formData.append('vk_id', hash.split('_-_')[2]);
        formData.append('action', 'reg_vk');

        await axios.post(`${process.env.REACT_APP_NEW_API}/technical_routes/auth_control`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            if (response.status === 200) {
                window.location.href = '/registration-vk/' + token_go;
            }
        }).catch(function (response) {
            if (response.status !== 200) {
                var errors = [];
                var show_pass = false;

                if (response.response.data.message === 'Ключ недействителен'){
                    errors['token'] = 'Недействительный токен';

                    show_pass = true;
                }

                if (response.response.data.message === 'Мы не узнали этот токен. Возможные проблемы: он написан с опечаткой, перепутаны токены для Прода и Стейджа или аккаунт с этим токеном не активирован' || response.response.data.message === 'Введенный токен от прода. Нужен токен от стейджа'){
                    errors['token'] = response.response.data.message;

                    show_pass = true;
                }

                if (response.response.data.message === 'Аккаунт с таким токеном уже зарегистрирован'){
                    errors['token'] = 'Аккаунт с таким токеном уже создан';

                    show_pass = true;
                }

                if (response.response.data.message === 'Аккаунт не зарегестрирован'){
                    errors['token'] = 'Такой токен не существует';

                    show_pass = true;
                }

                if(show_pass === false){
                    errors['token'] = response.response.data.message;
                }

                setErrors(errors);
            }
        });
    };

    const value = { registerVK, authVK };

    return <RegistrationVKContext.Provider value={value}>{children}</RegistrationVKContext.Provider>;
};

export const useRegistrationVK = () => useContext(RegistrationVKContext);