import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";

const TracerContext = createContext(undefined);

export const TracerProvider = ({ children }) => {
    const [traces, setTraces] = useState([]);

    const load = async () => {
        const asyncFn = async () => {
            var device_id = Cookies.get('device_id', false);

            if (!device_id){
                window.location.href = '/';
            }else {
                await axios.get(process.env.REACT_APP_NEW_API + '/technical_routes/get_trace?device_id=' + device_id, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'trainer_token': Cookies.get('session_id')
                    }
                }).then(function (response) {
                    if (response.status === 200) {
                        setTraces(response.data.traces)
                    }
                })
            }
        };

        asyncFn();
    };

    const value = { traces, load };

    return <TracerContext.Provider value={value}>{children}</TracerContext.Provider>;
};

export const useTracer = () => useContext(TracerContext);