import React, { useState, useEffect } from 'react';

import "./DebugMenuTrainer.css";
import $ from "jquery";
import Cookies from 'js-cookie';
import {useShop} from "../../../pages/Shop/ShopContext";

import none_pokemon_one from "../../../assets/images/none_pokemon_one.png"
import {useAuth} from "../../../pages/UserCore/Auth/AuthContext";
import {generatePokemonImageUrl} from "../../../helpers/helpers";

function time_to_animate(thises, start, end, add, start_color, end_color){
    var shag = 0;
    var shag_val = 0;
    var new_shag = 0
    var direct = false;

    if (start > end){
        shag_val = (start - end);
        shag = shag_val/10;
    }else{
        shag_val = (end - start);
        shag = shag_val/10;
        direct = true;
    }

    thises = thises;
    for (var i= 0; i < 11; i++){
        if (direct == true){
            new_shag = (shag*i) + start
        }else{
            new_shag = start - (shag*i)
        }

        setTimeout(function (thises, new_shag, add){
            if (new_shag === 0 || new_shag === 100){
                add = 0;
            }

            thises.css('background', 'linear-gradient(90deg, '+start_color+' '+new_shag+'%, '+end_color+' '+(new_shag+add)+'%)');
        }, (30*i), thises, new_shag, add)
    }
}

$('body').on('click', '.k_debug_trainer .k_debug_menu_c_kill_pokemon', function (){
    var hp = +$(this).attr('data-hp');
    var hp_add = 15;

    if (!$(this).hasClass('k_alive_pokemon')){
        hp -= 25;

        if (+hp <= 0) {
            $(this).addClass('k_alive_pokemon');
            $(this).html('Нокаут');

            $(this).closest('.k_debug_menu_c_pokemon_active').addClass('k_die')

            var dataz = {}
            dataz['pokemon_id'] = $(this).closest('.k_debug_menu_c_pokemon_active').attr('data-id');
            var thises_rem = $(this);
            $.ajax({
                url: process.env.REACT_APP_NEW_API + '/debug_menu/knockout_pokemon',
                type: 'POST',
                dataType: 'json',
                headers: { 'trainer_token': Cookies.get('session_id') },
                contentType: "application/json",
                data: JSON.stringify(dataz),
                success: function (data) {
                    Cookies.set('rerender_debug_menu', '1');

                    thises_rem.closest('.k_debug_menu_c_pokemon_active').css('order', '1');
                }
            });

        }

        time_to_animate($(this), +$(this).attr('data-hp'), hp, hp_add, '#258f25', 'red');
        $(this).attr('data-hp', hp);
    }

})

$('body').on('click', '.k_debug_menu_c_gen_pokemon', function () {
    var dataz = {}
    dataz['name'] = "generate";
    dataz['photo_id'] = -1;
    dataz['trainer_id'] = window.location.pathname.split('/')[2];
    var thises_rem = this;
    $('.k_main_error_text').remove();
    var thises = this;
    $.ajax({
        url: process.env.REACT_APP_NEW_API + '/debug_menu/generate_pokemon',
        type: 'POST',
        dataType: 'json',
        headers: { 'trainer_token': Cookies.get('session_id') },
        contentType: "application/json",
        data: JSON.stringify(dataz),
        success: function (data) {
            Cookies.set('rerender_debug_menu', '1');

            $.ajax({
                url: process.env.REACT_APP_NEW_API + '/pokemons?pokemon_id=' + data.id,
                type: 'GET',
                headers: { 'trainer_token': Cookies.get('session_id') },
                dataType: 'json',
                success: function (data) {
                    data = data.data[0];

                    if (data.photo_id){
                        data.photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(data.photo_id+'').padStart(3, '0')+'.png';
                    }else{
                        data.photo = none_pokemon_one;
                    }

                    var element_debug = $($('.k_debug_menu_c_pokemon')[0]).clone();
                    element_debug.find('.k_debug_menu_c_pokemon_photo img').attr('src', data.photo);
                    element_debug.find('.k_debug_menu_c_pokemon_name').html(data.name);
                    element_debug.find('.k_debug_menu_c_pokemon_id').html(data.id);
                    element_debug.attr('data-id', data.id);
                    element_debug.find('.k_debug_menu_c_pokemon_pokeball_status').html('Не привязан к покетболу');
                    element_debug.find('.k_debug_menu_c_pokemon_pokeball_change').html('Привязать');
                    element_debug.find('.k_debug_menu_c_pokemon_pokeball_change').attr('data-status', 0);
                    element_debug.removeClass('k_debug_menu_c_pokemon');
                    element_debug.addClass('k_debug_menu_c_pokemon_active');
                    element_debug.css('order', '0');

                    $('.k_debug_menu_c_pokemons').append(element_debug);

                }
            })
        },
        error: function (data){
            data = JSON.parse(data.responseText);

            $(thises).after('<font class="k_main_error_text k_f_error">'+data.message+'</font>')
        }
    });
})

$('body').on('click', '.k_debug_menu_c_delete_pokemon', function (){
    var hp = +$(this).attr('data-hp');
    var hp_add = 15;

    hp -= 25;

    if (+hp <= 0) {
        setTimeout(function (thises){
            var dataz = {}
            dataz['pokemon_id'] = $(thises).closest('.k_debug_menu_c_pokemon_active').attr('data-id');
            var headers = {'trainer_token': Cookies.get('session_id')}
            var thises_rem = thises;
            $.ajax({
                url: process.env.REACT_APP_MAIN_PORT_URL + process.env.REACT_APP_MAIN_PORT + '/pokemons',
                type: 'DELETE',
                dataType: 'json',
                headers: headers,
                contentType: "application/json",
                data: JSON.stringify(dataz),
                success: function (data) {
                    Cookies.set('rerender_debug_menu', '1');
                    $('.pokemons-dropdown__item').each(function (){
                        if (+$(this).attr('data-id') === +dataz['pokemon_id']){
                            $('.k_cart_trainer_pokemons_history').html( +$('.k_cart_trainer_pokemons_history').html() - 1);
                            if (+$('.k_cart_trainer_pokemons_history').html() <= 0)
                            {
                                $('.k_cart_trainer_pokemons_history_div').addClass('feature-empty');
                                $('.k_cart_trainer_pokemons_history_div #dropdown').addClass('disabled');
                            }else{
                                $('.k_cart_trainer_pokemons_history_div').removeClass('feature-empty');
                                $('.k_cart_trainer_pokemons_history_div #dropdown').removeClass('disabled');
                            }

                            thises_rem.closest('.k_debug_menu_c_pokemon_active').remove();
                            $(this).remove();
                        }
                    })
                }
            });
        }, 500, this)
    }

    time_to_animate($(this), +$(this).attr('data-hp'), hp, hp_add, 'red', 'black');
    $(this).attr('data-hp', hp);
})

$('body').on('click', '.k_debug_trainer .k_debug_menu_c_pokemon_pokeball_change', function (){
    var dataz = {}
    dataz['pokemon_id'] = $(this).closest('.k_debug_menu_c_pokemon_active').attr('data-id');
    dataz['trainer_id'] = window.location.pathname.split('/')[2];

    var thises = this;
    $('.k_main_error_text').remove();
    if (+$(this).attr('data-status') === 1){
        $.ajax({
            url: process.env.REACT_APP_NEW_API + '/debug_menu/delete_pokeball',
            type: 'POST',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                Cookies.set('rerender_debug_menu', '1');

                $(thises).parent().find('.k_debug_menu_c_pokemon_pokeball_status').html('Не привязан к покетболу');
                $(thises).html('Привязать');
                $(thises).attr('data-status', 0);
            }
        });
    }else{
        $.ajax({
            url: process.env.REACT_APP_NEW_API + '/debug_menu/add_pokeball',
            type: 'POST',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                Cookies.set('rerender_debug_menu', '1');

                $(thises).parent().find('.k_debug_menu_c_pokemon_pokeball_status').html('Привязан к покетболу');
                $(thises).html('Отвязать');
                $(thises).attr('data-status', 1)
            },
            error: function (data){
                data = JSON.parse(data.responseText);

                $(thises).parent().parent().after('<font class="k_main_error_text k_f_error">'+data.message+'</font>')
            }
        });
    }
})

$('body').on('click', '.k_debug_photo_trainer', function (){
    var dataz = {};
    dataz['trainer_id'] = window.location.pathname.split('/')[2];
    dataz['avatar_id'] = $(this).attr('data-id');
    var thises_avatar = this;
    $.ajax({
        url: process.env.REACT_APP_NEW_API + '/debug_menu/edit_trainer_avatar',
        type: 'PATCH',
        dataType: 'json',
        headers: {'trainer_token': Cookies.get('session_id')},
        contentType: "application/json",
        data: JSON.stringify(dataz),
        success: function (data) {
            Cookies.set('rerender_debug_menu', '1');

            $('.k_debug_photo_trainer').removeClass('feature-empty');
            $(thises_avatar).addClass('feature-empty');
        }
    });
})

var s_int = '';
$('body').on('keyup change', '.k_debug_trainer .k_debug_menu_c_input', function () {
    console.log(1);

    clearTimeout(s_int)
    var thises = $(this);
    s_int = setTimeout(function (thises){
        if (thises.val() !== '') {
            $('.k_main_error_text').remove();
            var dataz = {}
            var headers = {'trainer_token': Cookies.get('session_id')}
            dataz['trainer_id'] = window.location.pathname.split('/')[2];
            dataz[thises.attr('data-name')] = thises.val();
            $.ajax({
                url: process.env.REACT_APP_NEW_API + '/debug_menu/update_trainer',
                type: 'PATCH',
                dataType: 'json',
                headers: headers,
                contentType: "application/json",
                data: JSON.stringify(dataz),
                success: function (data) {
                    if (thises.attr('data-name') === 'level') {
                        Cookies.set('rerender_debug_menu_level', thises.val());
                    }


                    $('.k_cart_trainer_' + thises.attr('data-name')).html(thises.val());
                    Cookies.set('rerender_debug_menu_name', '.k_cart_trainer_' + thises.attr('data-name') + '::' + thises.val());
                },
                error: function (data) {
                    data = JSON.parse(data.responseText);
                    $(thises).after('<font class="k_main_error_text k_f_error">' + data.message + '</font>')
                }
            });
        }

        clearTimeout(s_int);
    }, 1200, thises)

})

const DebugMenuTrainer = ({trainer, pokemons}) => {
    const { shopList, load } = useShop();
    const { user } = useAuth();

    React.useEffect(() => {
        load()
    }, []);

    $('header').css('display', 'none');

    return (
        <main
            className="content his-open container"
        >
            <div className='k_debug_menu'>
                <div className='k_debug_menu_button'>
                    <div className='k_debug_b_icon'> > </div>
                </div>
                <div className='k_debug_menu_console k_debug_trainer'>
                    <div className='k_debug_menu_c_title'>Редактировать тренера</div>

                    <div className='k_debug_menu_c_flex'>
                        <label>уровень</label>
                        <input type='number' data-name='level' className='k_debug_menu_c_input k_debug_menu_c_trainer_level' defaultValue={trainer.level} />
                    </div>

                    <div className='k_debug_menu_c_flex'>
                        <label>Фото</label>
                        <div className='k_debug_photo_trainers'>
                            {shopList ? shopList.map((el, index) => (
                                <div className={'k_debug_photo_trainer k_debug_photo_trainer_exists' + (el.preload ? ' feature-empty fix_block_cursor' : (el.link === user.avatar_id ? ' feature-empty fix_block_cursor' : ' available'))} data-id={el.link} data-img={el.img}>
                                    <img src={el.img} alt="аватар тренера" />
                                </div>
                            )) : ''}
                        </div>
                    </div>

                    <div className='k_debug_menu_c_flex'>
                        <label>Имя</label>
                        <input type='text' data-name='name' className='k_debug_menu_c_input k_debug_menu_c_trainer_name' defaultValue={trainer.trainer_name} />
                    </div>

                    <div className='k_debug_menu_c_flex'>
                        <label>Город</label>
                        <input type='text' data-name='city' className='k_debug_menu_c_input k_debug_menu_c_trainer_city' defaultValue={trainer.city} />
                    </div>

                    <div className='k_debug_menu_c_b k_debug_menu_c_gen_pokemon' data-is-delete=''>Сгенерировать покемона</div>

                    <div className='k_debug_menu_c_pokemons'>
                        <div className='k_debug_menu_c_pokemon'>
                            <div className='k_debug_menu_c_pokemon_top'>
                                <div className='k_debug_menu_c_pokemon_photo'><img src=''/></div>
                                <div className='k_debug_menu_c_pokemon_info'>
                                    <div className='k_debug_menu_c_pokemon_name'>Pikachu</div>
                                    <div className='k_debug_menu_c_pokemon_id'>666</div>
                                </div>
                                <div className='k_debug_menu_c_pokemon_pokeball'>
                                    <div className='k_debug_menu_c_pokemon_pokeball_status'>Не привязан к покетболу</div>
                                    <div className='k_debug_menu_c_b k_debug_menu_c_pokemon_pokeball_change'>Привязать</div>
                                </div>
                            </div>
                            <div className='k_debug_menu_c_pokemon_actions'>
                                <div className='k_debug_menu_c_b k_debug_menu_c_kill_pokemon' data-hp='100'>В нокаут</div>
                            </div>
                        </div>
                        {pokemons ? pokemons.map((el, index) => (
                            <div className={"k_debug_menu_c_pokemon_active" + (el.status === 0 ? " k_die" : "")} data-id={el.id} style={el.status === 0 ? {'order': '9999'+index} : {'order': '0'+index}}>
                                <div className='k_debug_menu_c_pokemon_top'>
                                    <div className='k_debug_menu_c_pokemon_photo'>
                                        <img src={generatePokemonImageUrl(el.photo_id)} />
                                    </div>
                                    <div className='k_debug_menu_c_pokemon_info'>
                                        <div className='k_debug_menu_c_pokemon_name'>{el.name}</div>
                                        <div className='k_debug_menu_c_pokemon_id'>{el.id}</div>
                                    </div>
                                    <div className='k_debug_menu_c_pokemon_pokeball'>
                                        <div className='k_debug_menu_c_pokemon_pokeball_status'>
                                            {el.status === 1 && el.in_pokeball === 0 ? "Не привязан к покетболу" : "Привязан к покетболу"}
                                        </div>
                                        <div className='k_debug_menu_c_b k_debug_menu_c_pokemon_pokeball_change' data-status={el.status === 1 && el.in_pokeball === 0 ? 0 : 1}>
                                            {el.status === 1 && el.in_pokeball === 0 ? "Привязать" : "Отвязать"}
                                        </div>
                                    </div>
                                </div>
                                <div className='k_debug_menu_c_pokemon_actions'>
                                    {el.status === 0 ?
                                        <div className='k_debug_menu_c_b k_debug_menu_c_kill_pokemon k_alive_pokemon' data-hp='0' style={{'background': 'linear-gradient(90deg, #258f25 0%, red 0%)'}}>Нокаут</div> :
                                        <div className='k_debug_menu_c_b k_debug_menu_c_kill_pokemon' data-hp='100'>В нокаут</div>
                                    }
                                </div>
                            </div>
                        )) : ''}

                    </div>
                </div>
            </div>
        </main>
    );
};

export default DebugMenuTrainer;