import React, { useState, useEffect } from 'react';
import "./Tracer.css";

import $ from "jquery";

import {useTracer} from "./TracerContext";

$('body').on('click', '.k_tracer_one', function (){
    if($(this).find('.k_tracer_one_right_open').hasClass('active')){
        $(this).find('.k_tracer_one_right_open').removeClass('active');

        $(this).find('.k_tracer_one_bottom').slideUp();
    }else{
        $(this).find('.k_tracer_one_right_open').addClass('active');

        $(this).find('.k_tracer_one_bottom').slideDown();
    }
})

$('body').on('change keyup', '.k_search_trace', function (){
    var search_q = $(this).val();
    $('.k_tracer_one').each(function (){
        if ($(this).find('.k_tracer_one_right_title').html().toLowerCase().indexOf(search_q.toLowerCase()) !== -1){
            $(this).css('display', 'block');
        }else{
            $(this).css('display', 'none');
        }
    })
})

const TracerPage = () => {
    const {traces, load} = useTracer();

    React.useEffect(() => {
        load();
    },[]);

    return (
        <>
            <main className="content">
                <div className="gradient"></div>
                <h2 className="k_h2">Журнал запросов</h2>
                <div className="k_tracer_wrapper">
                    <div className="k_tracer_wrapper_search">
                        <input className="k_search_trace" type="text" placeholder="Поиск" />
                    </div>
                    <div className="k_tracer_wrappers">
                        {traces ? traces.map((el, index) => (
                            <div className="k_tracer_one">
                                <div className="k_tracer_one_ttop">
                                    <div className={"k_tracer_one_left stat_"+el.status}>
                                        <div className="k_tracer_one_left_id">#{index}</div>
                                        <div className="k_tracer_one_left_status">{el.status}</div>
                                    </div>
                                    <div className="k_tracer_one_right">
                                        <div className="k_tracer_one_right_date">{el.date}</div>
                                        <div className="k_tracer_one_right_title">{el.title}</div>
                                        <div className="k_tracer_one_right_speed">{el.ms+'ms'}</div>
                                        <div className="k_tracer_one_right_open"></div>
                                    </div>
                                </div>
                                <div className="k_tracer_one_bottom">
                                    <div className="k_tracer_one_bottom_content">{el.body}</div>
                                </div>
                            </div>
                        )) : ''}

                    </div>
                </div>
            </main>
        </>
    );
};

export default TracerPage;