import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";
import {usePremium} from "../../Premium/PremiumContext";

const AuthContext = createContext(undefined);
var userHasSendAuth_fix = false;

export const AuthProvider = ({ children, setPremiumstatus }) => {
    const [user, setUser] = useState(null);

    React.useEffect(() => {
        if (!userHasSendAuth_fix) {
            updateUserData();
            userHasSendAuth_fix = true;
        }
    }, []);

    const updateUserData = () => {
        const asyncFn = async () => {
            const session_id = Cookies.get('session_id');
            const firebase_token = Cookies.get('firebase_token');

            if (session_id) {
                await axios.post(`${process.env.REACT_APP_NEW_API}/technical_routes/auth_control`, {
                    action: 'auth',
                    api_key: session_id,
                    firebase_token: firebase_token
                }, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true
                }).then(function (response) {
                    if (response.status === 200) {
                        setUser(response.data);

                        Cookies.set('user_id', response.data.id, {expires: 365});
                        Cookies.set('login', response.data.login, {expires: 365});

                        setPremiumstatus(response.data.is_premium);
                    }
                }).catch(function (response) {
                    if (response.status !== 200) {
                        Cookies.set('user_auth', '0', {expires: 365});
                        Cookies.set('session_id', '', {
                            expires: 365,
                            domain: process.env.REACT_APP_MAIN_PORT_URL.replace('https://', '')
                        });
                        setUser(null);

                        $('.auth').each(function () {
                            if (!$(this).hasClass('profile')) {
                                $(this).css('display', 'block')
                            }
                        });
                    }
                });
            } else {
                setUser(null);

                $('.auth').each(function () {
                    if (!$(this).hasClass('profile')) {
                        $(this).css('display', 'block')
                    }
                });
            }
        };

        asyncFn();
    };

    const login = async (values, {setErrors}) => {
        var formData = new FormData();
        formData.append('email', values['email']);
        formData.append('pass_1', values['password']);
        formData.append('action', 'auth_trainer');

        await axios.post(`${process.env.REACT_APP_NEW_API}/technical_routes/auth_control`, formData, {
            withCredentials: true
        }).then(function (response) {
            if (response.status === 200) {
                setUser(response.data);
                setPremiumstatus(response.data.is_premium);

                if (window.location.pathname !== '/'){
                    window.location.href = '/'
                }
            }
        }).catch(function (response) {
            if (response.status !== 200) {
                setErrors({submit: response.response.data.message})
            }
        });
    };

    const logout = async () => {
        var formData = new FormData();
        formData.append('action', 'exit');

        await axios.post(`${process.env.REACT_APP_NEW_API}/technical_routes/auth_control`, formData, {
            withCredentials: true
        }).then(function (response) {
            if (response.status === 200) {
                Cookies.set('user_auth', '', { expires: 365 });
                Cookies.set('user_id', '', {expires: 365});
                Cookies.set('google_add', 'false', {expires: 365});
                setUser(null);

                window.location.reload();
            }
        });
    };

    const UpdateUserField = (field, value) => {
        setUser(prevUser => ({
            ...prevUser,
            [field]: value
        }))
    }

    const value = { user, UpdateUserField, login, logout, updateUserData };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);