import React, { createContext, useContext, useState } from 'react';
import axios from "axios";

const RegistrationContext = createContext(undefined);

export const RegistrationProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const register = async (values, {setErrors}) => {
        var formData = new FormData();
        formData.append('trainer_token', values['token']);
        formData.append('email', values['email']);
        formData.append('password', values['password']);
        formData.append('password_re', values['password_2']);

        await axios.post(`${process.env.REACT_APP_NEW_API}/trainers/reg`, formData, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(function (response) {
            if (response.status === 200) {
                window.location.href = '/confirmation_registration';
            }
        }).catch(function (response) {
            if (response.status !== 200) {
                var errors = [];
                var show_pass = false;

                if (response.response.data.message === 'Неверный токен'){
                    errors['token'] = 'Неверный токен';

                    show_pass = true;
                }

                if (response.response.data.message === 'Мы не узнали этот токен. Возможные проблемы: он написан с опечаткой, перепутаны токены для Прода и Стейджа или аккаунт с этим токеном не активирован' || response.response.data.message === 'Введенный токен от прода. Нужен токен от стейджа'){
                    errors['token'] = response.response.data.message;

                    show_pass = true;
                }

                if (response.response.data.message === 'Аккаунт с таким токеном уже зарегистрирован'){
                    errors['token'] = 'Аккаунт с таким токеном уже создан';

                    show_pass = true;
                }

                if (response.response.data.message === 'Аккаунт не зарегестрирован'){
                    errors['token'] = 'Такой токен не существует';

                    show_pass = true;
                }

                if (response.response.data.message === 'Аккаунт с такой почтой уже зарегистрирован'){
                    errors['email'] = 'Аккаунт с такой почтой уже создан';

                    show_pass = true;
                }

                if (response.response.data.message === 'Почта введена некорректно(email)'){
                    errors['email'] = 'Введите корректную почту';

                    show_pass = true;
                }


                if(show_pass === false){
                    errors['password'] = 'Аккаунт с такой почтой уже создан';
                }

                setErrors(errors);
            }
        });
    };

    const value = { user, register };

    return <RegistrationContext.Provider value={value}>{children}</RegistrationContext.Provider>;
};

export const useRegistration = () => useContext(RegistrationContext);