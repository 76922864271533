import React, { useState, useEffect } from 'react';

import "./TrainersListCard.css";
import {generateTrainerImageUrl} from "../../../helpers/helpers";
import CopyNumber from "../../Interactive/CopyNumber/CopyNumber";
import {useNavigate} from "react-router-dom";
import $ from "jquery";
import LevelOrAttack from "../../Interactive/LevelOrAttack/LevelOrAttack";
import base_cup_icon from "../../../assets/icons/base_cup_icon.png";
import base_premium_icon from "../../../assets/icons/base_premium_icon.png";

const TrainersListCard = ({trainer}) => {
    const img_trainer = generateTrainerImageUrl(trainer.avatar_id);
    const navigate = useNavigate();

    const handleLinkTrainer = (e) => {
        var el = $(e.target).closest('.trainers_list_card_link');

        navigate('/trainer/'+el.attr('data-id')+'/', {replace: false});
        window.scrollTo(0, 0);
    };

    return (
        <div className="trainers_list_card_link"  data-id={trainer.id} onClick={handleLinkTrainer}>
            <div className="trainers_list_card">
                <div className="trainers_list_card_left">
                    <div className="trainers_list_card_left_img"><img src={img_trainer}/></div>
                </div>
                <div className="trainers_list_card_right">
                    <div className="trainers_list_card_right_top">
                        <div className="trainers_list_card_right_top_name style_1_caption_24_500">{trainer.trainer_name}</div>
                        {trainer.level >= 5 ? <div className="single_page_body_content_title_icon single_page_body_content_title_icon_cup">
                            <img src={base_cup_icon} className="single_page_body_content_title_icon_img" alt="иконка кубка" />
                        </div> : ''}
                        {trainer.is_premium ? <div className="single_page_body_content_title_icon pokemon_one_body_content_title_icon_gold">
                            <img src={base_premium_icon} className="single_page_body_content_title_icon_img" alt="иконка премиума" />
                        </div> : ''}
                    </div>
                    <div className="trainers_list_card_right_center">
                        <LevelOrAttack value={trainer.level} type='level' />
                        <div className="pokeballs_icons" data-bol={trainer.pokemons_in_pokeballs.length}>
                            <div className="pokeballs_icon"></div>
                            <div className="pokeballs_icon"></div>
                            <div className="pokeballs_icon"></div>
                        </div>
                    </div>
                    <div className="trainers_list_card_right_botton">
                        <CopyNumber id={trainer.id}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainersListCard;