import logo from './logo.svg';
import './App.css';
import './all.css';

import {BrowserRouter as Router, Routes, Route, Outlet} from "react-router-dom";
import Header from "./components/Layout/Header/Header";
import Footer from "./components/Layout/Footer/Footer";
import PokemonListPage from "./pages/PokemonList/PokemonListPage";
import FilterBody from "./components/FilterPokemonsAndTrainers/FilterBody";

import {AuthProvider} from "./pages/UserCore/Auth/AuthContext";
import { useAuth } from './pages/UserCore/Auth/AuthContext';
import AuthLogin from './pages/UserCore/Auth/Auth';
import {PokemonListProvider} from "./pages/PokemonList/PokemonListContext";
import TrainerListPage from "./pages/TrainerList/TrainerListPage";
import {TrainerListProvider} from "./pages/TrainerList/TrainerListContext";
import PokemonOnePage from "./pages/PokemonOne/PokemonOnePage";
import {PokemonOneProvider, usePokemonOne} from "./pages/PokemonOne/PokemonOneContext";
import React from 'react';
import $ from "jquery";
import {RatingProvider} from "./pages/Rating/RatingContext";
import RatingPage from "./pages/Rating/RatingPage";
import {TrainerOneProvider} from "./pages/TrainerOne/TrainerOneContext";
import TrainerOnePage from "./pages/TrainerOne/TrainerOnePage";
import {kGetUrl, trainer_or_pokemons_open_lists} from "./helpers/helpers";
import RegistrationPage from "./pages/UserCore/Registration/RegistrationPage";
import {RegistrationProvider} from "./pages/UserCore/Registration/RegistrationContext";
import ConfirmationPage from "./pages/UserCore/Confirmation/ConfirmationPage";
import ChangePasswordPage from "./pages/UserCore/ChangePassword/ChangePasswordPage";
import {ChangePasswordProvider} from "./pages/UserCore/ChangePassword/ChangePasswordContext";
import PremiumPage from "./pages/Premium/PremiumPage";
import {PremiumProvider} from "./pages/Premium/PremiumContext";
import PaymentCardInfoPage from "./pages/Payment/PaymentCardInfo/PaymentCardInfoPage";
import {PaymentCardInfoProvider} from "./pages/Payment/PaymentCardInfo/PaymentCardInfoContext";
import ShopPage from "./pages/Shop/ShopPage";
import {ShopProvider} from "./pages/Shop/ShopContext";
import {ProfileProvider} from "./pages/Profile/ProfileContext";
import ProfilePage from "./pages/Profile/ProfilePage";
import TrainersGalleryPage from "./pages/TrainersGallery/TrainersGalleryPage";
import LogsPage from "./pages/Logs/LogsPage";
import ChangePasswordStep2Page from "./pages/UserCore/ChangePasswordStep2/ChangePasswordStep2Page";
import {ChangePasswordStep2Provider} from "./pages/UserCore/ChangePasswordStep2/ChangePasswordStep2Context";
import Cookies from "js-cookie";
import {PokemonExportProvider} from "./components/Pokemons/TrainerOne/PokemonExport/PokemonExportContext";
import {Payment3DSProvider} from "./pages/Payment/Payment3DS/Payment3DSContext";
import Payment3DSPage from "./pages/Payment/Payment3DS/Payment3DSPage";
import PaymentResultPage from "./pages/Payment/PaymentResult/PaymentResultPage";
import RegistrationVKPage from "./pages/UserCore/RegistrationVK/RegistrationVKPage";
import {RegistrationVKProvider} from "./pages/UserCore/RegistrationVK/RegistrationVKContext";
import Error from "./pages/Error/Error";
import {OptionsProvider} from "./components/Options/OptionsContext";
import {TracerProvider} from "./pages/Tracer/TracerContext";
import TracerPage from "./pages/Tracer/TracerPage";

import axios from "axios";


var url_local = window.location.pathname.split('/')[ 1 ];
if (kGetUrl('password_re') && url_local !== 'change_password_step_2'){
    window.location.href = '/change_password_step_2?password_re='+kGetUrl('password_re')+'&trainer_id='+kGetUrl('trainer_id');
}

if (kGetUrl('confirm_mail')){
    $.ajax({
        url: process.env.REACT_APP_NEW_API + '/trainers/confirm_email?key='+kGetUrl('confirm_mail'),
        type: 'GET',
        dataType: 'json',
        contentType: 'application/json',
        async: false,
        success: function(data){
            window.location.href = '/confirmation_registration_step_2';
        }, error: function (jqXHR){
            //pass
        }
    });
}

$(window).on('resize', function(){
    var trainer_img = $('.single_page_body_avatar_img');
    if (trainer_img[0]){
        var content_h = trainer_or_pokemons_open_lists();

        if (content_h !== 0){
            $('.pokemons-dropdown__list, .history_battles').css('height', content_h+'px');
        }
    }
});

axios.interceptors.request.use((config) => {
    config.metadata = { startTime: new Date() };
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    var device_id = Cookies.get('device_id', false);

    if (device_id) {
        const endTime = new Date();
        const duration = endTime - response.config.metadata.startTime;

        $.ajax({
            url: process.env.REACT_APP_NEW_API + '/technical_routes/save_trace',
            type: 'POST',
            contentType: "application/json",
            global: false,
            data: JSON.stringify({
                'device_id': device_id,
                'status': '' + response.status,
                'ms': '' + duration,
                'body': JSON.stringify(response.data),
                'title': response.config.url
            }),
            dataType: 'json'
        });
    }

    return response;
}, (error) => {
    return Promise.reject(error);
});

const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();

    console.log(user)

    return user ? children : <><AuthLogin /><Footer is_control="footer_control" /></>
};

const LayoutWithHeaderAndFooterAndFilters = ({title, is_protect, type}) => (
    <>
        {is_protect ?
            <ProtectedRoute>
                <Header />
                <FilterBody title={title} type={type} />
                <Outlet />
                <Footer />
            </ProtectedRoute> : ''
        }

        {!is_protect ?
            <div>
                <Header />
                <FilterBody title={title} type={type} />
                <Outlet />
                <Footer />
            </div> : ''}
    </>
);

const LayoutAndHeaderAndFooter = ({is_protect}) => (
    <>
        {is_protect ?
            <ProtectedRoute>
                <Header />
                <Outlet />
                <Footer />
            </ProtectedRoute> : ''
        }

        {!is_protect ?
            <div>
                <Header />
                <Outlet />
                <Footer />
            </div> : ''}
    </>
);

const LayoutOnlyFooter = () => (
    <>
        <Outlet />
        <Footer is_control="footer_control" />
    </>
);

function deleteAllCookies() {
    document.cookie.split(';').forEach(cookie => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    });
}

if (!Cookies.get('fix_cookies')){
    deleteAllCookies();

    Cookies.set('fix_cookies', 'fixed', { expires: 365 })
}

function App() {
  return (
    <div className="App">
        <AuthProvider>
            <TracerProvider>
            <OptionsProvider>
            <RegistrationVKProvider>
            <Payment3DSProvider>
            <PokemonExportProvider>
            <ChangePasswordStep2Provider>
            <ProfileProvider>
            <ShopProvider>
            <PaymentCardInfoProvider>
            <PremiumProvider>
            <ChangePasswordProvider>
            <RegistrationProvider>
            <TrainerOneProvider>
            <PokemonOneProvider>
            <RatingProvider>
            <TrainerListProvider>
                <PokemonListProvider>
                    <Router>
                        <Routes>
                            <Route element={<LayoutWithHeaderAndFooterAndFilters title="Покемоны" is_protect={true} type="pokemons" />}>
                                <Route path="/" element={<PokemonListPage />} />
                            </Route>
                            <Route element={<LayoutWithHeaderAndFooterAndFilters title="Тренеры" is_protect={true} type="trainers" />}>
                                <Route path="/trainers" element={<TrainerListPage />} />
                            </Route>
                            <Route element={<LayoutAndHeaderAndFooter is_protect={true}/>}>
                                <Route path="/rating" element={<RatingPage />} />
                            </Route>
                            <Route element={<LayoutAndHeaderAndFooter is_protect={true}/>}>
                                <Route path="/pokemon/:pokemonId" element={<PokemonOnePage />} />
                            </Route>
                            <Route element={<LayoutAndHeaderAndFooter is_protect={true}/>}>
                                <Route path="/trainer/:trainerId" element={<TrainerOnePage />} />
                            </Route>

                            <Route element={<LayoutAndHeaderAndFooter is_protect={true}/>}>
                                <Route path="/premium" element={<PremiumPage />} />
                            </Route>

                            <Route path="/payment/:patmentId" element={<PaymentCardInfoPage />} />
                            <Route path="/payment_3ds" element={<Payment3DSPage />} />
                            <Route path="/payment_success" element={<PaymentResultPage />} />
                            <Route path="/payment_mistake" element={<PaymentResultPage type="mistake" />} />

                            <Route element={<LayoutAndHeaderAndFooter is_protect={true}/>}>
                                <Route path="/shop" element={<ShopPage />} />
                            </Route>

                            <Route element={<LayoutAndHeaderAndFooter is_protect={true}/>}>
                                <Route path="/profile" element={<ProfilePage />} />
                            </Route>

                            <Route path="/trainers/avatars" element={<TrainersGalleryPage />} />
                            <Route path="/pokemons/avatars" element={<TrainersGalleryPage />} />

                            <Route path="/logs" element={<LogsPage />} />

                            <Route element={<LayoutAndHeaderAndFooter is_protect={true}/>}>
                                <Route path="/tracer" element={<TracerPage />} />
                            </Route>

                            <Route element={<LayoutOnlyFooter/>}>
                                <Route path="/login" element={<AuthLogin no_hidden={true} />} />
                                <Route path="/registration" element={<RegistrationPage />} />
                                <Route path="/registration-vk/:Hash" element={<RegistrationVKPage />} />
                                <Route path="/confirmation_registration" element={<ConfirmationPage title="Регистрация" desc_1="Мы&nbsp;отправили подтверждение регистрации на&nbsp;вашу почту.
                                Перейдите по&nbsp;ссылке в&nbsp;письме, чтобы&nbsp;завершить процесс регистрации" add_class="confirmation_pass_step_1" link_href="/registration" />} />
                                <Route path="/confirmation_registration_step_2" element={<ConfirmationPage title="Регистрация завершена" desc_1="Вы успешно подтвердили вашу почту" icon_ok={true} link_name="Перейти ко входу" add_class="confirmation_pass_step_1" />} />
                                <Route path="/confirmation_new_password_email" element={<ConfirmationPage title="Восстановление пароля" desc_1="Мы отправили подтверждение на вашу почту. Перейдите по ссылке в письме, чтобы продолжить" desc_2="Нет письма? Проверьте папку «Спам»" add_class="confirmation_pass_step_1" link_href="/change_password" />} />
                                <Route path="/confirmation_new_password_step_2" element={<ConfirmationPage title="Новый пароль сохранен" desc_1="Вы успешно сменили пароль" icon_ok={true} link_name="Перейти ко входу" add_class="confirmation_pass_step_1" />} />
                                <Route path="/change_password" element={<ChangePasswordPage />} />
                                <Route path="/change_password_step_2" element={<ChangePasswordStep2Page />} />
                            </Route>

                            <Route path="/*" element={<Error status="404" title='Такая страница не&nbsp;найдена' />} />
                            <Route path="/404" element={<Error status="404" title='Такая страница не&nbsp;найдена' />} />
                            <Route path="/500" element={<Error status="500" title='Что-то пошло не&nbsp;так' />} />
                        </Routes>
                    </Router>
                </PokemonListProvider>
            </TrainerListProvider>
            </RatingProvider>
            </PokemonOneProvider>
            </TrainerOneProvider>
            </RegistrationProvider>
            </ChangePasswordProvider>
            </PremiumProvider>
            </PaymentCardInfoProvider>
            </ShopProvider>
            </ProfileProvider>
            </ChangePasswordStep2Provider>
            </PokemonExportProvider>
            </Payment3DSProvider>
            </RegistrationVKProvider>
            </OptionsProvider>
            </TracerProvider>
        </AuthProvider>

        <Error status="500" is_over={false} is_all="k_is_all" title='Что-то пошло не&nbsp;так' />
    </div>
  );
}

export default App;
