import React, {useState} from 'react';
import {useTrainerOne} from "./TrainerOneContext";
import "./TrainerOne.css";
import "../TrainerAndPokemonOnePage.css"

import {useAuth} from "../UserCore/Auth/AuthContext";

import CopyNumber from "../../components/Interactive/CopyNumber/CopyNumber";
import BackButton from "../../components/Interactive/BackButton/BackButton";

import base_premium_icon from "../../assets/icons/base_premium_icon.png"
import base_cup_icon from "../../assets/icons/base_cup_icon.png"
import none_trainer_one from "../../assets/images/none_trainer_one.png"

import PokemonHistoryList from "../../components/Pokemons/PokemonHistoryList/PokemonHistoryList";
import {generateTrainerImageUrl, GetIdRarery, hasUrlPath, kGetUrl} from "../../helpers/helpers";
import TrainerAchievements from "../../components/Pokemons/TrainerOne/TrainerAchievements/TrainerAchievements";
import PokemonPokemonList from "../../components/Pokemons/PokemonPokemonList/PokemonPokemonList";
import PokemonExport from "../../components/Pokemons/TrainerOne/PokemonExport/PokemonExport";
import PokemonPremium from "../../components/Pokemons/PokemonPremium/PokemonPremium";
import PokemonAvatarChange from "../../components/Pokemons/PokemonAvatarChange/PokemonAvatarChange";
import PokemonUserSettings from "../../components/Pokemons/PokemonUserSettings/PokemonUserSettings";
import {useNavigate, useParams} from "react-router-dom";
import DebugMenuTrainer from "../../components/Pokemons/DebugMenuTrainer/DebugMenuTrainer";

import $ from "jquery";
import Cookies from 'js-cookie';

const TrainerOnePage = () => {
    const [isAllowRender, setisAllowRender] = useState(false);
    const { user, logout } = useAuth();
    const { trainer, achievements, battles, battlesCount, battlesIsNextPage, pokemons, pokemonsCount, pokemonsIsNextPage, rate, getAllInfo, load_trainer_battles, load_trainer_pokemons, UpdateTrainerField } = useTrainerOne();

    const params = useParams();
    const navigate = useNavigate();

    var trainer_id = params.trainerId;
    var trainer_level_fix = trainer.level;
    var in_pokeball = 0;

    React.useEffect(() => {
        setisAllowRender(false)

        const fetchData = async () => {
            await getAllInfo({ trainer_id }).then(function (){
                setisAllowRender(true)
            })
        }

        fetchData();
    },[params.trainerId])

    React.useEffect(() => {
        if (kGetUrl('debug_trainer_active') !== "1") {
            setInterval(function (){
                if (Cookies.get('rerender_debug_menu') === '1') {
                    Cookies.set('rerender_debug_menu', '0');
                    window.location.reload();
                }

                if (Cookies.get('rerender_debug_menu_level') && Cookies.get('rerender_debug_menu_level') !== 'false') {
                    /*var rerender_debug_menu_level = Cookies.get('rerender_debug_menu_level');

                    UpdateTrainerField('level', rerender_debug_menu_level);
                    trainer.level = rerender_debug_menu_level;*/

                    Cookies.set('rerender_debug_menu_level', 'false');
                    window.location.reload();
                }
                if (Cookies.get('rerender_debug_menu_name') && Cookies.get('rerender_debug_menu_name') !== 'false') {
                    /*var name_and_class = Cookies.get('rerender_debug_menu_name').split('::');
                    $(name_and_class[0]).html(name_and_class[1]);*/
                    Cookies.set('rerender_debug_menu_name', 'false');
                    window.location.reload();
                }
            }, 500)
        }
    }, [])

    if (trainer.level > 5 && isAllowRender){
        trainer_level_fix = 5;
    }

    if (trainer.pokemons_in_pokeballs && trainer.pokemons_in_pokeballs.length > 0 && isAllowRender){
        in_pokeball = trainer.pokemons_in_pokeballs.length;
    }

    const exit_button_f = () => {
        logout()
    }

    return (
        <>
            { (kGetUrl('debug_trainer_active') !== "1") ?
                <div className="single_page">
                    <div className="single_page_body">
                        <BackButton />
                        <div className="single_page_body_avatar">
                            <div className="single_page_body_avatar_gradient" style={{"left": "-46.5px"}}></div>
                            <img className="single_page_body_avatar_img" src={isAllowRender ? generateTrainerImageUrl(trainer.avatar_id) : none_trainer_one} alt="image" />
                        </div>
                        <div className="single_page_body_content">
                            <div className="single_page_body_content_title">
                                <p className="single_page_body_content_title_text style_1_caption_16_500 style_1_text_left">
                                    {trainer.level >= 5 && isAllowRender ? 'Чемпион' : 'Тренер'}
                                </p>
                                <div className="single_page_body_content_title_icons">
                                    <h2 className="single_page_body_content_title_icons_name style_1_heading_38_400_pokemon_classic style_1_text_left">{isAllowRender ? trainer.trainer_name : '...... .....'}</h2>
                                    {trainer.level >= 5 && isAllowRender ? <div className="single_page_body_content_title_icon single_page_body_content_title_icon_cup">
                                        <img src={base_cup_icon} className="single_page_body_content_title_icon_img" alt="иконка кубка" />
                                    </div> : ''}
                                    {trainer.is_premium && isAllowRender ? <div className="single_page_body_content_title_icon pokemon_one_body_content_title_icon_gold">
                                        <img src={base_premium_icon} className="single_page_body_content_title_icon_img" alt="иконка премиума" />
                                    </div> : ''}
                                </div>
                            </div>
                            <div className="single_page_body_content_inner">
                                <div className="single_page_body_content_inner_top">
                                    <div className="single_page_body_content_inner_top_list_attr single_page_body_content_inner_box">
                                        {rate > 0 && isAllowRender ?
                                            <div className="attr_rate_trainer single_page_body_content_inner_top_list_attr_one">
                                                <span className="single_page_body_content_inner_top_list_attr_one_name style_1_caption_16_400 style_1_text_left">Место</span>
                                                <div className="single_page_body_content_inner_top_list_attr_one_text style_1_caption_16_400 style_1_text_left">
                                                    {rate}
                                                    <div className={"k_cart_trainer_rate_img k_cart_trainer_rate_img_"+((rate > 3 && rate < 30) ? (rate > 10 && rate < 30) ? 30 : 10 : rate)}></div>
                                                </div>
                                            </div> : ''}
                                        <div className="attr_id_trainer single_page_body_content_inner_top_list_attr_one">
                                            <span className="single_page_body_content_inner_top_list_attr_one_name style_1_caption_16_400 style_1_text_left">ID тренера</span>
                                            <div className="single_page_body_content_inner_top_list_attr_one_text style_1_caption_16_400 style_1_text_left">
                                                <CopyNumber id={isAllowRender ? trainer.id : '....'} />
                                            </div>
                                        </div>
                                        <div className="attr_city_trainer single_page_body_content_inner_top_list_attr_one">
                                            <span className="single_page_body_content_inner_top_list_attr_one_name style_1_caption_16_400 style_1_text_left">Город</span>
                                            <div className="single_page_body_content_inner_top_list_attr_one_text style_1_caption_16_400 style_1_text_left">
                                                {isAllowRender ? trainer.city : '.....'}
                                            </div>
                                        </div>
                                        <div className="single_page_body_content_inner_top_list_attr_one">
                                            <span className="single_page_body_content_inner_top_list_attr_one_name style_1_caption_16_400 style_1_text_left">Покеболы</span>
                                            <span className="single_page_body_content_inner_top_list_attr_one_text style_1_caption_16_400 style_1_text_left">{isAllowRender ? in_pokeball : '.'}</span>
                                            <ul className="single_page_body_content_inner_top_list_attr_one_slide">
                                                <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? in_pokeball : 0} data-full="3"></li>
                                                <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? in_pokeball : 0} data-full="3"></li>
                                                <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? in_pokeball : 0} data-full="3"></li>
                                            </ul>
                                        </div>
                                        <div className="single_page_body_content_inner_top_list_attr_one last_child">
                                            <span className="single_page_body_content_inner_top_list_attr_one_name style_1_caption_16_400 style_1_text_left">Уровень</span>
                                            <span className="single_page_body_content_inner_top_list_attr_one_text style_1_caption_16_400 style_1_text_left">{isAllowRender ? trainer.level : '.'}</span>
                                            <ul className="single_page_body_content_inner_top_list_attr_one_slide">
                                                <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                                <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                                <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                                <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                                <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                                <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                                <li className="single_page_body_content_inner_top_list_attr_one_slide_i" data-active={isAllowRender ? trainer_level_fix : 0} data-full="5"></li>
                                            </ul>
                                        </div>
                                        <TrainerAchievements achievements={isAllowRender ? achievements : []} />
                                    </div>
                                    {user.id+'' === trainer.id+'' ?
                                        <PokemonExport battles={isAllowRender ? battles : []} trainer_id={isAllowRender ? trainer.id : '....'} /> : ''
                                    }
                                </div>
                                <div className="k_mobile k_content_trainer">
                                    <PokemonPokemonList pokemons={isAllowRender ? pokemons : []} isNextPage={pokemonsIsNextPage} load_trainer_pokemons={load_trainer_pokemons} pokemonsCount={pokemonsCount} />
                                    <PokemonHistoryList history_list_uncorrect={isAllowRender ? battles : []} battlesCount={isAllowRender ? battlesCount : 0} isNextPage={battlesIsNextPage} load_trainer_battles={load_trainer_battles} />
                                    {user.id + '' === trainer.id + '' && isAllowRender ?
                                        <>
                                            <PokemonExport battles={isAllowRender ? battles : []} trainer_id={isAllowRender ? trainer.id : '....'} />
                                            <PokemonPremium user={user} />
                                            <PokemonAvatarChange />
                                            <PokemonUserSettings />
                                            <div className="k_exit_mobile_button style_1_caption_14_500" onClick={exit_button_f}>Выйти</div>
                                        </>: ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ""
            }

            { (kGetUrl('debug_trainer_active') === "1") ?
                <>
                    <DebugMenuTrainer trainer={isAllowRender ? trainer : {}} pokemons={isAllowRender ? pokemons : []}></DebugMenuTrainer>
                </>
                 : ""
            }
        </>
    );
};
//
export default TrainerOnePage;