import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";
import {RenderPageError} from "../../helpers/helpers";

const TrainerOneContext = createContext(undefined);

export const TrainerOneProvider = ({ children }) => {
    const [trainer, setTrainer] = useState([]);
    const [achievements, setAchievements] = useState([]);
    const [battles, setBattles] = useState([]);
    const [battlesCount, setBattlesCount] = useState(0);
    const [battlesIsNextPage, setBattlesIsNextPage] = useState(false);

    const [pokemons, setPokemons] = useState([]);
    const [pokemonsCount, setpokemonsCount] = useState(0);
    const [pokemonsIsNextPage, setpokemonsIsNextPage] = useState(false);

    const [rate, setRate] = useState('');

    const load_trainer_battles = async ({trainer_id, page}) => {
        const asyncFn = async ({trainer_id, page}) => {
            var data = await axios.post(process.env.REACT_APP_NEW_API + '/technical_routes/single_trainer_battles', JSON.stringify({ 'trainer_id': trainer_id, 'page': page }), {
                headers: {
                    'Content-Type': 'application/json',
                    'trainer_token': Cookies.get('session_id')
                }
            })

            if(data.data.battles.length > 10){
                data.data.battles.pop();
                setBattlesIsNextPage(true);
            }else{
                setBattlesIsNextPage(false);
            }

            setBattles(battles.concat(data.data.battles));
        };

        await asyncFn({trainer_id, page});
    };

    const load_trainer_pokemons = async ({trainer_id, page}) => {
        const asyncFn = async ({trainer_id, page}) => {
            var data = await axios.post(process.env.REACT_APP_NEW_API + '/technical_routes/single_trainer_pokemons', JSON.stringify({ 'trainer_id': trainer_id, 'page': page }), {
                headers: {
                    'Content-Type': 'application/json',
                    'trainer_token': Cookies.get('session_id')
                }
            })

            if(data.data.pokemons.length > 10){
                data.data.pokemons.pop();
                setpokemonsIsNextPage(true);
            }else{
                setpokemonsIsNextPage(false);
            }

            setPokemons(pokemons.concat(data.data.pokemons));
        };

        await asyncFn({trainer_id, page});
    };

    const load_trainer = async ({trainer_id, all_data = false}) => {
        const asyncFn = async ({trainer_id, all_data = false}) => {
            if (!/^-?[\d.]+(?:e-?\d+)?$/.test(trainer_id)){
                RenderPageError(404);
                return
            }

            await axios.get(process.env.REACT_APP_NEW_API + '/trainers?trainer_id='+trainer_id+'&page=0', {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'trainer_token': Cookies.get('session_id')
                }
            }).then(async function (response) {
                if (response.status === 200) {
                    if (response.data.data) {
                        setTrainer(response.data.data[0]);

                        if (all_data){
                            var data = await axios.post(process.env.REACT_APP_NEW_API + '/technical_routes/single_trainer_data', JSON.stringify({ 'trainer_id': trainer_id }), {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'trainer_token': Cookies.get('session_id')
                                }
                            })

                            if(data.data.battles.length > 10){
                                data.data.battles.pop();
                                setBattlesIsNextPage(true);
                            }else{
                                setBattlesIsNextPage(false);
                            }

                            setBattles(data.data.battles);
                            setBattlesCount(data.data.battles_count)


                            if(data.data.pokemons.length > 10){
                                data.data.pokemons.pop();
                                setpokemonsIsNextPage(true);
                            }else{
                                setpokemonsIsNextPage(false);
                            }

                            setPokemons(data.data.pokemons);
                            setpokemonsCount(data.data.pokemons_count)

                            setAchievements(data.data.achievements);
                            setRate(data.data.rate)
                        }
                    }else{
                        RenderPageError(404);
                    }
                }
            }).catch(function (error){
                RenderPageError(error.request ? error.request.status : 500);
            })
        };

        await asyncFn({trainer_id, all_data});
    };

    const getAllInfo = async ({trainer_id}) => {
        return await load_trainer({trainer_id: trainer_id, all_data: true})
    }

    const UpdateTrainerField = (field, value) => {
        setTrainer(prevTrainer => ({
            ...prevTrainer,
            [field]: value
        }))
    }

    const value = { trainer, achievements, battles, battlesCount, battlesIsNextPage, pokemons, pokemonsCount, pokemonsIsNextPage, rate, load_trainer, getAllInfo, load_trainer_battles, load_trainer_pokemons, UpdateTrainerField };

    return <TrainerOneContext.Provider value={value}>{children}</TrainerOneContext.Provider>;
};

export const useTrainerOne = () => useContext(TrainerOneContext);