import React, {useEffect} from 'react';
import {
    Box,
    FormHelperText,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import './RegistrationVK.css';

import Cookies from 'js-cookie';
import $ from "jquery";

import { useRegistrationVK } from "./RegistrationVKContext";
import Gradient from "../../../components/Layout/Gradient/Gradient";
import ClassicTitle from "../../../components/PopupsWhite/ClassicTitle/ClassicTitle";
import SubTitleAndSubText from "../../../components/PopupsWhite/SubTitleAndSubText/SubTitleAndSubText";
import InputStandart from "../../../components/PopupsWhite/InputStandart/InputStandart";
import SendButtonStandart from "../../../components/PopupsWhite/SendButtonStandart/SendButtonStandart";
import LinkStandart from "../../../components/PopupsWhite/LinkStandart/LinkStandart";

const RegistrationVKPage = () => {
    const { registerVK, authVK } = useRegistrationVK();

    var hash = window.location.pathname.split('/')[2];

    useEffect(() => {
        if (hash.split('_-_')[0] !== 'add' && Cookies.get('google_add') !== 'true') {
            const authVKWrapper = async () => {
                await authVK()
            }

            authVKWrapper();
        } else {
            if (Cookies.get('session_id') && Cookies.get('session_id') !== 'null' && Cookies.get('session_id') !== '' && Cookies.get('google_add') === 'true') {
                Cookies.set('google_add', 'false', { expires: 365 });
                $.ajax({
                    url: process.env.REACT_APP_NEW_API + '/technical_routes/auth_control',
                    type: 'POST',
                    data: {
                        trainer_token: Cookies.get('session_id'),
                        secure_hash: hash.split('_-_')[1],
                        vk_id: hash.split('_-_')[2],
                        action: 'reg_vk'
                    },
                    success: function (data) {
                        window.location.href = '/profile?connect_trainer_page=1';
                    },
                    error: function (data) {
                        data = JSON.parse(data.responseText);
                        window.location.href = '/profile?error=google&message=' + data.message;
                    }
                });
            } else {
                setTimeout(function () {
                    $('.k_form_hidden').css('visibility', 'visible');
                    $('.style_1_popup_vk_token').css('visibility', 'visible');
                }, 700);
            }
        }
    }, [])

    return (
        <>
            <Gradient type="gradient_bottom" />
            <section className="style_1_popup_vk_token style_1_popup_white_out style_1_popup_all_page" style={{'visibility': 'hidden'}}>
                <div className="style_1_popup_white_in">
                    <ClassicTitle title="Битва покемонов" />
                    <SubTitleAndSubText link="/login" subtitle="Регистрация с помощью VK" subtext='Токен можно получить в <a href="https://t.me/qa_studio_kotik_bot" target="_blank">Котике</a> → отправь <a href="https://storage.yandexcloud.net/qastudio/tracker/stage_create_trainer.png" target="_blank">команду /stage</a>' />

                    <Formik
                        initialValues={{
                            token: ''
                        }}
                        validationSchema={Yup.object().shape({
                            token: Yup.string().max(255).required('Введите токен')
                        })}
                        onSubmit={async (values, {setErrors}) => {
                            await registerVK(values, {setErrors});
                        }}
                    >
                        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
                            <Form>
                                <div className="style_1_popup_white_form_wrapper_fields">
                                    <InputStandart label="Токен" name="token" onBlur={handleBlur} onChange={handleChange} touched={touched.token} error={errors.token} value={values.token} />
                                </div>


                                {errors.submit && (
                                    <Box mt={3} className='k_error_submit'>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}

                                <SendButtonStandart title="Завершить регистрацию" is_disabled={isSubmitting}/>
                                <LinkStandart link="/login" title="Уже зарегистрированы? Войти"/>
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </>
    );
};

export default RegistrationVKPage;