import React, { useState, useEffect } from 'react';
import "./Shop.css";

import {useShop} from "./ShopContext";
import {useAuth} from "../UserCore/Auth/AuthContext";
import {useNavigate} from "react-router-dom";

const ShopPage = () => {
    const { user } = useAuth();
    const { shopList, load } = useShop();
    const navigate = useNavigate();

    React.useEffect(() => {
        load()
    }, []);

    return (
        <div className="shop_page">
            <main className="content container">
                <div className="gradient"></div>
                <section className="pokemon" >
                    <div className="pokemon__content">
                        <h1 className="pokemon__title style_1_heading_38_400_pokemon_classic style_1_text_left">Магазин</h1>
                    </div>
                </section>
                <section className="shop">
                    <ul className="shop__list">
                        {shopList.map((item, index) => {
                            return (
                                <li className={'shop__item' + (item.preload ? ' feature-empty fix_block_cursor' : (item.link === user.avatar_id ? ' feature-empty fix_block_cursor' : ' available'))} key={index}>
                                    <img
                                        className="shop__img"
                                        src={item.img}
                                        alt="Аватар тренера № {item.link}"
                                    />
                                    <h2 className="shop__title">{item.name}</h2>
                                    <p className="shop__price">{item.price}</p>
                                    <button
                                        onClick={() => navigate("/payment/"+item.link)}
                                        className="shop__button"
                                    >
                                        Купить
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </section>
            </main>
        </div>
    );
};

export default ShopPage;