import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import base_burger_menu_icon from "../../../assets/icons/base_burger_menu_icon.svg"

import "./../FilterTypeBurgerMenu.css";
import $ from "jquery";
import {useTrainerList} from "../../../pages/TrainerList/TrainerListContext";

const FilterTypeBurgerMenu = () => {
    const [showBurgerMenu, setShowBurgerMenu] = React.useState(false);
    const [menu_badge_val, setMenu_badge_val] = React.useState(0);
    const { sort_new } = useTrainerList();

    const handleOpenMenu = (event) => {
        setShowBurgerMenu(!showBurgerMenu);
    };

    React.useEffect(() => {
        function closePopup() {
            setShowBurgerMenu(false);
        }

        function handlecloseBurgerMenu(e) {
            const target = e.target.closest(".filter_type_burger_menu");

            if (!target) {
                closePopup();
            }
        }

        $(document).off('click', handlecloseBurgerMenu);
        $(document).on('click', handlecloseBurgerMenu);
    }, []);

    const handleResetFilter = async (event) => {
        setMenu_badge_val(0);
        $('.k_filter_level .filter_type_burger_menu_body_filter_radio_p input').prop('checked', false);

        await sort_new('');
    };

    const handleUpdateSort = async (event) => {
        var is_select_filters = false;
        var s_menu_badge_val = 0;

        $('.k_filter_level .filter_type_burger_menu_body_filter_radio_p input').each(function () {
            if ($(this).prop('checked')) {
                is_select_filters = true;
                s_menu_badge_val++;
            }
        });

        setMenu_badge_val(s_menu_badge_val);

        await sort_new('');
    };


    return (
        <div className="filter_type_burger_menu">
            <div className={"filter_type_burger_menu_button style_1_interactive_button_link " + (menu_badge_val > 0 || showBurgerMenu ? 'active': 'none')} onClick={handleOpenMenu}>
                <img src={base_burger_menu_icon}/>
                <div className={"pokemon__filter-counter " + (menu_badge_val > 0 ? 'active': 'none')}>{menu_badge_val}</div>
            </div>

            <div className={"filter_type_burger_menu_body " + (showBurgerMenu ? 'open' : 'close')}>
                <button className="filter_type_burger_menu_body_icon_close" onClick={handleOpenMenu} />

                <div className="filter_type_burger_menu_top">
                    <div className="style_1_caption_24_500 style_1_text_left">Фильтры</div>
                    <button type="button" className={"filter_type_burger_menu_top_reset " + (menu_badge_val > 0 ? 'active': 'none')} onClick={handleResetFilter}>Сбросить</button>
                </div>

                <div className="filter_type_burger_menu_body_filter k_filter_level">
                    <p className="filter_type_burger_menu_body_filter_title style_1_caption_16_500 style_1_text_left">Уровень</p>
                    <div className="filter_type_burger_menu_body_filter_radio_p">
                        <label className="filter_type_burger_menu_body_filter_radio_label">
                            <input
                                type="checkbox"
                                className="filter_type_burger_menu_body_filter_radio_label_checkbox"
                                name="attack"
                                value={1}
                                onClick={handleUpdateSort}
                            />
                            <span className="filter_type_burger_menu_body_filter_radio_label_span style_1_caption_13_500">1</span>
                        </label>
                        <label className="filter_type_burger_menu_body_filter_radio_label">
                            <input
                                type="checkbox"
                                className="filter_type_burger_menu_body_filter_radio_label_checkbox"
                                name="attack"
                                value={2}
                                onClick={handleUpdateSort}
                            />
                            <span className="filter_type_burger_menu_body_filter_radio_label_span style_1_caption_13_500">2</span>
                        </label>
                        <label className="filter_type_burger_menu_body_filter_radio_label">
                            <input
                                type="checkbox"
                                className="filter_type_burger_menu_body_filter_radio_label_checkbox"
                                name="attack"
                                value={3}
                                onClick={handleUpdateSort}
                            />
                            <span className="filter_type_burger_menu_body_filter_radio_label_span style_1_caption_13_500">3</span>
                        </label>
                        <label className="filter_type_burger_menu_body_filter_radio_label">
                            <input
                                type="checkbox"
                                className="filter_type_burger_menu_body_filter_radio_label_checkbox"
                                name="attack"
                                value={4}
                                onClick={handleUpdateSort}
                            />
                            <span className="filter_type_burger_menu_body_filter_radio_label_span style_1_caption_13_500">4</span>
                        </label>
                        <label className="filter_type_burger_menu_body_filter_radio_label">
                            <input
                                type="checkbox"
                                className="filter_type_burger_menu_body_filter_radio_label_checkbox"
                                name="attack"
                                value={5}
                                onClick={handleUpdateSort}
                            />
                            <span className="filter_type_burger_menu_body_filter_radio_label_span style_1_caption_13_500">5</span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="filter_type_burger_menu_body_bg"></div>
        </div>
    );
};

export default FilterTypeBurgerMenu;