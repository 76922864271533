import $ from "jquery";
import {useLocation} from "react-router-dom";

// Проверка что текущий путь ссылки совпадает с параметром path
export const hasUrlPath = (path) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();

    return location.pathname === path;
};

// Ссылка на картинку покемона
export const generatePokemonImageUrl = (id) => {
    const formattedId = String(id).padStart(3, '0');
    return `https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/${formattedId}.png`;
};

// Ссылка на картинку тренера
export const generateTrainerImageUrl = (id) => {
    const formattedId = String(id).padStart(2, '0');
    return `https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/${formattedId}.png`;
};

// Формируем дату ~11.12.2024 11:24
export const FormatDate1 = (currect_date) => {
    var date = new Date(currect_date);
    var new_date = date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear().toString() + ' ' + addZero(date.getHours().toString()) + ':' + addZero(date.getMinutes().toString());

    return new_date;
};

// Формируем дату ~11.12.2024
export const FormatDate2 = (currect_date) => {
    var date = new Date(currect_date);
    var new_date = date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.'+date.getFullYear().toString().substr(2,2);

    return new_date;
};

function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

export const trainer_or_pokemons_open_lists = (open_pokemons=0, open_battles=0) => {
    var content_h = 0;
    var trainer_export_button = $('.k_mobile > .k_cart_trainer_export');
    var trainer_export_button_left = $('.k_cart_trainer_export');
    var content_pokemon_or_trainer = $('.single_page_body_content_inner_top');
    var exit_auth = $('.k_trainer_exit');
    var collumn_2_buttons = $('.k_cart_trainer_premium, .k_cart_trainer_avatar_change, .k_profile_trainer');

    var list_pokemons = $('.pokemon_one_body_content_inner_pokemons');
    var list_battles = $('.pokemon_one_body_content_inner_hystory_battle');

    var content__box_pokemon_top = $('.pokemon_one_body_content_inner_top');

    var e = false;
    if ((list_pokemons.hasClass('list-open') || open_pokemons === 1) && open_pokemons !== 2) {
        e = list_pokemons.find('.pokemons-top');

        if (list_battles.css('display') !== 'none') {
            list_battles.css('display', 'none');
        }
    }else{
        if (list_battles.css('display') === 'none') {
            list_battles.css('display', 'flex');
        }
    }

    if ((list_battles.hasClass('list-open') || open_battles === 1) && open_battles !== 2) {
        e = list_battles.find('.pokemon_one_body_content_inner_hystory_battle_link');

        if (list_pokemons.css('display') !== 'none') {
            list_pokemons.css('display', 'none');
        }
    }else{
        if (list_pokemons.css('display') === 'none') {
            list_pokemons.css('display', 'flex');
        }
    }

    if ((list_pokemons.hasClass('list-open') || list_battles.hasClass('list-open') || open_pokemons === 1 || open_battles === 1) && open_battles !== 2 && open_pokemons !== 2) {
        collumn_2_buttons.each(function (){
            if ($(this).css('visibility', 'visible')){
                $(this).css('display', 'none');
            }
        });
    }else{
        collumn_2_buttons.each(function (){
            if ($(this).css('visibility', 'visible')){
                $(this).css('display', 'flex');
            }
        });
    }

    if (window.innerWidth < 1600){
        if ((list_pokemons.hasClass('list-open') || list_battles.hasClass('list-open') || open_pokemons === 1 || open_battles === 1) && open_battles !== 2 && open_pokemons !== 2){
            if (content_pokemon_or_trainer.css('display') !== 'none'){
                content_pokemon_or_trainer.css('display', 'none');
            }
            if (window.innerWidth <= 992){
                if (exit_auth.css('display') !== 'none') {
                    exit_auth.css('display', 'none');
                }
            }

            if (trainer_export_button.css('display') === 'flex' ) {
                trainer_export_button_left.css('display', 'flex');
                trainer_export_button.css('display', 'none');
            }

            if (content__box_pokemon_top.css('display') === 'flex' ) {
                content__box_pokemon_top.css('display', 'none');
            }
        }else{
            if (window.innerWidth <= 992){
                if (exit_auth.css('display') === 'none') {
                    exit_auth.css('display', 'block');
                }
            }

            if (content_pokemon_or_trainer.css('display') === 'none'){
                content_pokemon_or_trainer.css('display', 'flex');
            }

            if (trainer_export_button.css('visibility', 'visible')){
                trainer_export_button_left.css('display', 'none');
                trainer_export_button.css('display', 'flex');
            }

            if (content__box_pokemon_top.css('display') === 'none' ) {
                content__box_pokemon_top.css('display', 'flex');
            }
        }
    }else{
        if (content_pokemon_or_trainer.css('display') === 'none'){
            content_pokemon_or_trainer.css('display', 'flex');
            $('.trainer-item .content__inner').css('gap', '');
        }

        if (trainer_export_button.css('display') === 'flex' ) {
            trainer_export_button_left.css('display', 'flex');
            trainer_export_button.css('display', 'none');
        }

        if (content__box_pokemon_top.css('display') === 'none' ) {
            content__box_pokemon_top.css('display', 'flex');
        }
    }

    if ((list_pokemons.hasClass('list-open') || list_battles.hasClass('list-open') || open_pokemons === 1 || open_battles === 1) && open_battles !== 2 && open_pokemons !== 2) {
        if (window.innerHeight > 500) {
            content_h = window.innerHeight - ($('header').outerHeight() + $('footer').outerHeight() + $('.pokemon_one_body_content_title').outerHeight() + $(e).outerHeight() + 36 + 12 - 27)

            if (window.innerWidth <= 992) {
                content_h -= 100;
            }

            if (content_h < 372) {
                content_h = 372
            } else {
                if ($('section.trainer-item.single-trainer').css('margin-bottom') !== '0px'){
                    $('section.trainer-item.single-trainer').css('margin-bottom', '0px');
                }
            }
        }
    }

    return content_h;
};

//
export const KNumberPriceFormat = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

//
export const CheckButtonTop = (class_button, class_parent, height) => {
    if ($(window).width() < 460) {
        var parent_height = $('.'+class_button).closest('.'+class_parent).outerHeight();
        var window_height = $(window).outerHeight();
        var add_height = $('.'+class_button).closest('.profile').find('.profile-mobile').height();

        if ((window_height - (parent_height + add_height)) <= height + 120){
            $('.'+class_button).css('position', 'relative');
            $('.'+class_button).css('bottom', '0px');
            $('.'+class_button).css('padding', '2px 0px 24px');
            $('.'+class_button).css('margin', '0px 0px');
        }else{
            $('.'+class_button).css('position', 'absolute');
            $('.'+class_button).css('bottom', '24px');
            $('.'+class_button).css('padding', '0 0px');
            //$('.'+class_button+'.button_to_down_buy').css('padding', '0 12px');
            $('.'+class_button).css('margin', '2px 12px 0px');
        }
    }
};

//
export const DayTitle = (number) => {
    if (number > 10 && [11, 12, 13, 14].includes(number%100)) return 'дней';
    var last_num = number%10;
    if (last_num == 1) return 'день';
    if ([2,3,4].includes(last_num)) return 'дня';
    if ([5,6,7,8,9, 0].includes(last_num)) return 'дней';
};


function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

function hasNumbers(t) {
    var regex = /\d/g;
    return regex.test(t);
}

function isNumeric(str) {
    if (typeof str != "string"){
        return false
    }
    return /*!isNaN(str) */!isNaN(parseFloat(str))
}

function hasUpper(string) {
    var i = 0;
    var character = '';
    while (i <= string.length) {
        character = string.charAt(i);
        if (!isNaN(character * 1)) {} else {
            if (character === character.toUpperCase()) {
                if (!isNumeric(character) && character.match(/[a-z]/i)){
                    return true;
                }
            }
        }
        i++;
    }
}
//
export const KCheckField = (k_class_obj, ktype, pass_rename = false) => {
    if (ktype == 'text'){
        if (  $(k_class_obj).val() == '' ){
            $(k_class_obj).addClass('k_f_error');
            $(k_class_obj).parent().find('.auth__error').fadeIn();
            return false;
        }else{
            $(k_class_obj).removeClass('k_f_error');
            $(k_class_obj).parent().find('.auth__error').fadeOut();
            return true;
        }
    }
    if (ktype == 'email'){
        if (  $(k_class_obj).val() == '' || isEmail( $(k_class_obj).val()) == false ){
            $(k_class_obj).addClass('k_f_error');
            $(k_class_obj).parent().find('.auth__error').fadeIn();
            return false;
        }else{
            $(k_class_obj).removeClass('k_f_error');
            $(k_class_obj).parent().find('.auth__error').fadeOut();
            return true;
        }
    }
    if (ktype == 'pass'){
        var error = false;

        var has_num = hasNumbers( $(k_class_obj+'_1').val());
        var has_Upper = hasUpper( $(k_class_obj+'_1').val());
        if (
            $(k_class_obj+'_1').val() == '' ||
            $(k_class_obj+'_1').val().length < 6 ||
            !has_num ||
            !has_Upper ||
            !$(k_class_obj+'_1').val().match(/(?=.*[A-Z])(?=.*\d)/) ||
            !$(k_class_obj+'_1').val().match(/^[A-Z0-9._%+#$!@-]{2,}$/i) ||
            !$(k_class_obj+'_1').val().match(/^\S+$/i)
        ){
            $(k_class_obj+'_1').addClass('k_f_error');
            $(k_class_obj+'_1').parent().find('.auth__error').fadeIn();

            if ( $(k_class_obj+'_1').val() !== ''){
                error = 'Пароль не соответствует<br> требованиям';
            }else{
                error = 'Введите пароль';
            }

            if (pass_rename &&  $(k_class_obj+'_1').val() !== ''){
                error = 'Новый пароль не соответствует требованиям.<br>Минимум 6 символов, только латиница, 1 заглавная буква и 1 цифра';
            }

            return error;
        }else{
            if ( $(k_class_obj+'_1').val() === ''){
                $(k_class_obj+'_1').addClass('k_f_error');
                $(k_class_obj+'_1').parent().find('.auth__error').fadeIn();
                $(k_class_obj+'_1').parent().find('.auth__error').html('Введите пароль');
            }else{
                $(k_class_obj+'_1').removeClass('k_f_error');
                $(k_class_obj+'_1').parent().find('.auth__error').fadeOut();
            }

            if (
                $(k_class_obj+'_2').val() != '' &&
                $(k_class_obj+'_2').val() ==  $(k_class_obj+'_1').val()
            ){
                return true;
            }else{
                $(k_class_obj+'_2').addClass('k_f_error');
                $(k_class_obj+'_2').parent().find('.auth__error').fadeIn();
                $(k_class_obj+'_2').parent().find('.auth__error').html('Пароли не совпадают');
                return 'Пароли не совпадают';
            }
        }
    }
};

//
export const kGetUrl = (name) => {
    if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
        return decodeURIComponent(name[1]);
}

// Логика для поля номера карты
export const CardAppendAt = (s, n, t) => {
    return s.substring(0, n) + t + s.substring(n);
}

export const CardReplaceAt = (s, n, t) => {
    return s.substring(0, n) + t + s.substring(n + 1);
}

export const IsNumber = (n) => {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

export const GetIdRarery = () => {
    var pokemon_id = window.location.pathname.split('/');
    if (pokemon_id[pokemon_id.length - 1]){
        pokemon_id = pokemon_id[pokemon_id.length - 1];
    }else{
        pokemon_id = pokemon_id[pokemon_id.length - 2];
    }

    return pokemon_id
}

export const RenderPageError = (status) => {
    if (status === 0){
        $('.not-found__title').html('Что-то пошло не&nbsp;так')
        status = 500
    }

    $('.not-found__text').html(status+'...')

    if (status === 404){
        $('.not-found__title').html('Такая страница не найдена')
    }

    $('.filter_body').remove();
    $('header + div').html($('#k_is_all').html());
}