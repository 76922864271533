import React from 'react';
import TrainersListCard from "../../components/Pokemons/TrainersList/TrainersListCard";
import {useTrainerList} from "./TrainerListContext";
import none_trainer from "../../assets/images/none_trainer.png"
import "./TrainerList.css";
import none_pokemon from "../../assets/images/none_pokemon.png";
import base_list_error from "../../assets/images/base_list_error.png";

const TrainerListPage = (sort_info) => {
    const { nextPage, filteredTrainers, hasLoadTrainers, load, sort_new } = useTrainerList();

    React.useEffect(() => {
        load()
    }, []);

    const handleLoadMore = async () => {
        await sort_new({load_more:true})
    };

    var empty__title = 'Никого не найдено';
    var empty__subtitle = 'Попробуйте изменить параметры запроса';
    var empty__img = none_trainer
    if (filteredTrainers === -1){
        empty__title = 'Не удалось загрузить данные'
        empty__subtitle = 'Попробуйте зайти через несколько минут или обновите страницу'
        empty__img = base_list_error
    }

    return (
        <div>
            <div className="trainers_list">
                <div className="gradient"></div>
                {filteredTrainers && filteredTrainers.length > 0 && filteredTrainers !== -1 ? filteredTrainers.map((trainer) => (
                    <TrainersListCard trainer={trainer} />
                )) : (hasLoadTrainers ?
                    <div className="empty">
                        <img className="empty__img" src={empty__img} alt="картинка"/>
                        <h2 className="empty__title">{empty__title}</h2>
                        <p className="empty__subtitle">{empty__subtitle}</p>
                    </div> : '')
                }
            </div>
            {nextPage && filteredTrainers && filteredTrainers.length > 0 ?
                <div className="k_load_more">
                    <button className="style_1_caption_16_500 style_1_button_background" onClick={handleLoadMore}>Загрузить еще</button>
                </div> : ''}
        </div>
    );
};

export default TrainerListPage;