import React, { useState, useEffect } from 'react';
import PokemonsListCard from "../../components/Pokemons/PokemonsList/PokemonsListCard";
import axios from "axios";
import FilterTypeBurgerMenu from "../../components/FilterPokemonsAndTrainers/FilterPokemons/FilterTypeBurgerMenu";
import {usePokemonList} from "./PokemonListContext";
import none_pokemon from "../../assets/images/none_pokemon.png"
import base_list_error from "../../assets/images/base_list_error.png"
import "./PokemonList.css"
import $ from "jquery";

const PokemonListPage = (sort_info) => {
    const { nextPage, filteredPokemons, load, sort_new } = usePokemonList();

    React.useEffect(() => {
        load()
    }, []);

    const handleLoadMore = async () => {
        await sort_new({load_more:true})
    };

    var empty__title = 'Никого не найдено';
    var empty__subtitle = 'Попробуйте изменить параметры запроса';
    var empty__img = none_pokemon
    if (filteredPokemons === -1){
        empty__title = 'Не удалось загрузить данные'
        empty__subtitle = 'Попробуйте зайти через несколько минут или обновите страницу'
        empty__img = base_list_error
    }

    return (
        <div>
            <div className="pokemon_list">
                <div className="gradient"></div>
                {filteredPokemons && filteredPokemons !== -1 ? filteredPokemons.map((pokemon) => (
                    <PokemonsListCard pokemon={pokemon} />
                )) :
                    <div className="empty">
                        <img className="empty__img" src={empty__img} alt="картинка"/>
                        <h2 className="empty__title">{empty__title}</h2>
                        <p className="empty__subtitle">{empty__subtitle}</p>
                    </div>
                }
            </div>
            {nextPage ?
                <div className="k_load_more">
                    <button className="style_1_caption_16_500 style_1_button_background" onClick={handleLoadMore}>Загрузить еще</button>
                </div> : ''}
        </div>
    );
};

export default PokemonListPage;