import React from 'react';
import './HeaderMobile.css';
import Cookies from 'js-cookie';
import $ from "jquery";

import user_menu_pokemon from "../../../../assets/icons/user_menu/user_menu_pokemon.svg"
import user_menu_pokemon_active from "../../../../assets/icons/user_menu/user_menu_pokemon_active.svg"

import user_menu_coaches from "../../../../assets/icons/user_menu/user_menu_coaches.svg"
import user_menu_coaches_active from "../../../../assets/icons/user_menu/user_menu_coaches_active.svg"

import user_menu_rating from "../../../../assets/icons/user_menu/user_menu_rating.svg"
import user_menu_rating_active from "../../../../assets/icons/user_menu/user_menu_rating_active.svg"

import base_premium_icon from "../../../../assets/icons/base_premium_icon.png";

import {NavLink} from "react-router-dom";
import {generateTrainerImageUrl, hasUrlPath} from "../../../../helpers/helpers";
import {useAuth} from "../../../../pages/UserCore/Auth/AuthContext";
import {usePremium} from "../../../../pages/Premium/PremiumContext";

const HeaderMobile = () => {
    const { user } = useAuth();
    const [isMobileKeyboardOpen, setisMobileKeyboardOpen] = React.useState(true);
    const { statusPremium } = usePremium();

    React.useEffect(() => {
        // определяем что открылась клавиатура
        const listener = () => {
            const MIN_KEYBOARD_HEIGHT = 250;

            const isMobile = window.innerWidth < 993;
            const isKeyboardOpen = isMobile && window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height;

            console.log(isKeyboardOpen)
            console.log(window.screen.height - MIN_KEYBOARD_HEIGHT)
            console.log(window.visualViewport.height)

            if (isMobile) {
                if (isKeyboardOpen) {
                    setisMobileKeyboardOpen(false);
                } else {
                    setisMobileKeyboardOpen(true);
                }
            } else {
                setisMobileKeyboardOpen(false);
            }
        }

        window.visualViewport.removeEventListener('resize', listener);
        window.visualViewport.addEventListener('resize', listener);
    }, [])

    const handleLinkSelfTrainer = (e) => {
        Cookies.set('search_pokemons', '', { expires: 365 });
        Cookies.set('search_trainers', '', { expires: 365 });

        console.log(window.location.pathname);
        if (window.location.pathname === '/trainer/'+(user?.id ? user.id : '')+'/'){
            $('.pokemon_one_body_content_inner_hystory_battle_link_button.down').click();
        }

        window.scrollTo(0, 0);
    }

    return (
        <div className="header_mobile" style={!isMobileKeyboardOpen || hasUrlPath('/premium') ? {'display': 'none'} : {}}>
            <ul className="header_mobile_list">
                <li className="header_mobile_list_one">
                    <NavLink to="/" onClick={handleLinkSelfTrainer} className={"header_mobile_list_one_link style_1_caption_12_500" + (hasUrlPath("/") ? " active" : "")}>
                        <img alt="Иконка покебола" src={hasUrlPath("/") ? user_menu_pokemon_active : user_menu_pokemon} className="header_mobile_list_one_link_icon"></img>
                        Покемоны
                    </NavLink>
                </li>
                <li className="header_mobile_list_one">
                    <NavLink to="/trainers" onClick={handleLinkSelfTrainer} className={"header_mobile_list_one_link style_1_caption_12_500" + (hasUrlPath("/trainers") ? " active" : "")}>
                        <img alt="Иконка тренеров" src={hasUrlPath("/trainers") ? user_menu_coaches_active : user_menu_coaches} className="header_mobile_list_one_link_icon"></img>
                        Тренеры
                    </NavLink>
                </li>
                <li className="header_mobile_list_one">
                    <NavLink to="/rating" onClick={handleLinkSelfTrainer} className={"header_mobile_list_one_link style_1_caption_12_500" + (hasUrlPath("/rating") ? " active" : "")}>
                        <img alt="Иконка рейтинга" src={hasUrlPath("/rating") ? user_menu_rating_active : user_menu_rating} className="header_mobile_list_one_link_icon"></img>
                        Рейтинг
                    </NavLink>
                </li>
                <li className="header_mobile_list_one">
                    <NavLink to={'/trainer/'+(user?.id ? user.id : '')+'/'} onClick={handleLinkSelfTrainer} className="header_mobile_list_one_link style_1_caption_12_500">
                        <div className="header_mobile_list_one_link_profile">
                            {statusPremium ? <div className="header_mobile_list_one_link_profile_premium_icon">
                                <img alt="Иконка премиума" src={base_premium_icon}/>
                            </div> : ''}
                            <div className="header_mobile_list_one_link_profile_wrapper">
                                <img alt="Иконка тренера" src={generateTrainerImageUrl(user?.avatar_id ? user.avatar_id : '')}></img>
                            </div>
                        </div>
                        Профиль
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default HeaderMobile;