import React, { useState, useEffect } from 'react';

import "./PokemonsListCard.css";
import {generatePokemonImageUrl} from "../../../helpers/helpers";
import base_pokeball_icon from "../../../assets/icons/base_pokeball_icon.svg"
import base_skull_icon from "../../../assets/icons/base_skull_icon.svg"
import base_max_attack from "../../../assets/icons/base_max_attack.svg"
import CopyNumber from "../../Interactive/CopyNumber/CopyNumber";
import $ from "jquery";
import {useNavigate} from "react-router-dom";
import LevelOrAttack from "../../Interactive/LevelOrAttack/LevelOrAttack";

const PokemonsListCard = ({pokemon}) => {
    const img_pokemon = generatePokemonImageUrl(pokemon.photo_id);
    const navigate = useNavigate();

    const handleLinkPokemon = (e) => {
        var el = $(e.target).closest('.pokemons_list_card');

        navigate('/pokemon/'+el.attr('data-id')+'/', {replace: false});
        window.scrollTo(0, 0);
    };

    return (
        <div className={"pokemons_list_card " + (pokemon.status ? "" : "pokemon_dead") + ( pokemon.legend ? " max_attack" : "")} data-id={pokemon.id} onClick={handleLinkPokemon}>
            <div className="pokemons_list_card_top">
                <div className="pokemons_list_card_top_img"><img src={img_pokemon}/></div>
            </div>
            <div className="pokemons_list_card_center">
                <div className="pokemons_list_card_center_name">{pokemon.name}</div>
                { pokemon.legend ? <div className="icon_max_attack"><img src={base_max_attack}/></div> : ''}
            </div>
            <div className="pokemons_list_card_bottom">
                { pokemon.in_pokeball ? <div className="icon_pokeball"><img src={base_pokeball_icon}/></div> : ''}
                { pokemon.status ? '' : <div className="icon_skull"><img src={base_skull_icon}/></div>}
                <LevelOrAttack value={pokemon.attack} />
                <CopyNumber id={pokemon.id}/>
            </div>
        </div>
    );
};

export default PokemonsListCard;