import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";
import {RenderPageError} from "../../helpers/helpers";

const PokemonListContext = createContext(undefined);

export const PokemonListProvider = ({ children }) => {
    const [nextPage, setnextPage] = useState(false);
    const [currectPage, setCurrectPage] = useState(1);
    const [filteredPokemons, setFilteredPokemons] = useState([]);

    const load = async () => {
        const asyncFn = async () => {
            await axios.get(process.env.REACT_APP_NEW_API + '/pokemons?status=1&page=1', {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(function (response) {
                if (response.status === 200) {
                    setFilteredPokemons(response.data.data);
                    setnextPage(response.data.next_page);
                }
            }).catch(function (error){
                if(error.request){
                    if(error.request.status === 500){
                        setFilteredPokemons(-1);
                        return
                    }
                }

                RenderPageError(error.request ? error.request.status : 500);
            })
        };

        asyncFn();
    };

    const sort_new = async ({load_more=false}) => {
        var data = {};

        data.sort = $('.k_coach_sort_pc .filter_type_sort_body_form_radio:checked').val();

        if (load_more){
            setCurrectPage(currectPage+1);

            data.page = currectPage+1;
        }else{
            setCurrectPage(1);

            data.page = 1;
        }

        var i_attack = 1;
        var menu_badge_val = 0;
        data.attack = '';
        $('.k_filter_attack .filter_type_burger_menu_body_filter_radio_p input').each(function () {
            if (i_attack === 6) {
                i_attack = 1;
            }

            if ($(this).prop('checked')) {
                if (i_attack === 5) {
                    data.attack += '5,6,7,';
                } else {
                    data.attack += i_attack + ',';
                }

                menu_badge_val++;
            }
            i_attack++;
        });

        if ($($('.k_pokemon_f_alive')[0]).prop('checked')) {
            delete data.status;
        } else {
            data.status = 1;
        }

        if ($($('.k_pokemon_f_has_pokeboll')[0]).prop('checked')) {
            data.in_pokeball = 1;
        } else {
            delete data.in_pokeball;
        }

        if (data.attack !== '') {
            if (data.attack) {
                data.attack = data.attack.slice(0, -1);
            }
        } else {
            delete data.attack;
        }

        if (Cookies.get('search_pokemons') !== '' && Cookies.get('search_pokemons') !== undefined && Cookies.get('search_pokemons') !== 'undefined') {
            data.name = Cookies.get('search_pokemons');
        } else {
            delete data.name;
        }

        await axios.get(process.env.REACT_APP_NEW_API + '/pokemons?' + $.param(data), {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            if (response.status === 200) {
                if (load_more){
                    setFilteredPokemons(filteredPokemons.concat(response.data.data))
                }else{
                    setFilteredPokemons(response.data.data);
                }

                setnextPage(response.data.next_page);
            }
        }).catch(function (error){
            RenderPageError(error.request ? error.request.status : 500);
        })
    };

    const value = { filteredPokemons, nextPage, sort_new, load };

    return <PokemonListContext.Provider value={value}>{children}</PokemonListContext.Provider>;
};

export const usePokemonList = () => useContext(PokemonListContext);