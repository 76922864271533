import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";

const OptionsContext = createContext(undefined);
var OptionsSend_fix = false;

export const OptionsProvider = ({ children }) => {
    const [optionsArr, setOptionsArr] = useState(false);

    const statusOptions = async () => {
        if (!optionsArr){
            if (!OptionsSend_fix) {
                const asyncOptionsLoad = async () => {
                    await axios.get(process.env.REACT_APP_NEW_API + '/technical_routes/get_options', {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(function (response) {
                        if (response.status === 200) {
                            var data = response.data;

                            var admin_whitelist = JSON.parse(data.admin_whitelist);
                            var admin_whitelist_exist = false;
                            for (var i = 0; i < admin_whitelist.length; i++) {
                                if (+Cookies.get('user_id') === +admin_whitelist[i]) {
                                    admin_whitelist_exist = true;
                                }

                                if (+Cookies.get('login') === +admin_whitelist[i]) {
                                    admin_whitelist_exist = true;
                                }

                                if (+Cookies.get('vk_auth_id') === +admin_whitelist[i]) {
                                    admin_whitelist_exist = true;
                                }
                            }

                            if (data.history_battle_status === 'on' && (admin_whitelist_exist || admin_whitelist.length <= 0)) {
                                data.history_battle_status = 'on';
                            } else {
                                data.history_battle_status = 'off';
                            }

                            if (data.achievements_status === 'on' && (admin_whitelist_exist || admin_whitelist.length <= 0)) {
                                data.achievements_status = 'on';
                            } else {
                                data.achievements_status = 'off';
                            }

                            setOptionsArr(data);
                        }
                    })
                };

                asyncOptionsLoad();
                OptionsSend_fix = true;
            }
        }
    };

    React.useEffect(() => {
        statusOptions();
    }, []);

    const value = { optionsArr, statusOptions };

    return <OptionsContext.Provider value={value}>{children}</OptionsContext.Provider>;
};

export const useOptions = () => useContext(OptionsContext);