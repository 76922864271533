import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import FilterTypeSearch from "./FilterPokemons/FilterTypeSearch";
import FilterTypeBurgerMenu from "./FilterPokemons/FilterTypeBurgerMenu";
import FilterTypeSort from "./FilterPokemons/FilterTypeSort";

import FilterTypeSearchTrainers from "./FilterTrainers/FilterTypeSearch";
import FilterTypeBurgerMenuTrainers from "./FilterTrainers/FilterTypeBurgerMenu";
import FilterTypeSortTrainers from "./FilterTrainers/FilterTypeSort";

import "./FilterBody.css";

const FilterBody = ({title, type}) => {
    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 75) {
          setSticky(true);
        } else {
          setSticky(false);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (
        <div className={"filter_body" + (isSticky ? ' sticky' : '')}>
            <h1 className="style_1_heading_38_400_pokemon_classic">{title}</h1>
            {type === 'pokemons' ?
                (<div className={"filter_header" + (isSticky ? ' sticky' : '')} style={{'display': 'contents'}}>
                    <FilterTypeSort/>
                    <div className="right_block">
                        <FilterTypeSearch/>
                        <FilterTypeBurgerMenu/>
                    </div>
                </div>) :
                (<div className={"filter_header" + (isSticky ? ' sticky' : '')} style={{'display': 'contents'}}>
                    <FilterTypeSortTrainers/>
                    <div className="right_block">
                        <FilterTypeSearchTrainers/>
                        <FilterTypeBurgerMenuTrainers/>
                    </div>
                </div>)
            }
        </div>
    );
};

FilterBody.propTypes = {
    title: PropTypes.string,
};

export default FilterBody;