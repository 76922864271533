import React from 'react';

import './PaymentMainTitle.css';
import $ from "jquery";
import {NavLink} from "react-router-dom";
import base_arrow_left from "../../../assets/icons/base_arrow_left.svg";

const PaymentMainTitle = () => {
    return (
        <div className="payment_main_title">
            <NavLink className="payment_main_title_link" onClick={function (){

                var href_link = $('.payment_form_card_back_wrapper').attr('href');
                if ($('.payment_form_card_back_wrapper_cap')[0]) {
                    href_link = $('.payment_form_card_back_wrapper_cap').attr('href')
                }

                setTimeout(function (href){
                    window.location.href = href;
                }, 500, href_link);

                return false;
            }}>
                <img src={base_arrow_left} alt='стрелка' className="payment_main_title_link_img" />
            </NavLink>
            <h2 className="payment_main_title_h2 style_1_caption_16_500">Оплата</h2>
        </div>
    );
};

export default PaymentMainTitle;