import React, { createContext, useContext, useState } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import $ from "jquery";
import Auth from "../UserCore/Auth/Auth";
import {AuthProvider} from "../UserCore/Auth/AuthContext";

const PremiumContext = createContext(undefined);

export const PremiumProvider = ({ children }) => {
    const [statusPremium, setstatusPremium] = useState(false);

    const setPremiumstatus = (status) => {
        setstatusPremium(status);
    };

    const value = { statusPremium, setPremiumstatus };

    return (
        <PremiumContext.Provider value={value}>
            <AuthProvider setPremiumstatus={setPremiumstatus}>
                {children}
            </AuthProvider>
        </PremiumContext.Provider>
    );
};

export const usePremium = () => useContext(PremiumContext);